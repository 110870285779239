import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ZOOM_MEETING_PIP } from '../../constants';
import { PipContainerMode, PipContainerType, usePipContainer } from '../../pip-container';
import { ZoomAppInstance } from '../types';
import { ZoomMeetingContextState, ZoomMeetingContextType, ZoomMeetingLaunchOptions } from './types';

export default function useZoomMeetingContextProviderVM() {
  const appRef = useRef<ZoomAppInstance>(null);

  const [container, setContainer] = useState<HTMLElement | null>(document.body);
  const [pipMode, setPipMode] = useState(PipContainerMode.PIP);
  const [canAutoJoin, setCanAutoJoin] = useState(true);

  const {
    id: pipId,
    isOpen,
    ref: pipRef,
    close,
    open,
    getContextData,
    updateContextData,
  } = usePipContainer<ZoomMeetingContextState>({
    id: ZOOM_MEETING_PIP,
    type: PipContainerType.ZOOM_MEETING,
  });

  const disableAutoJoin = useCallback(function disableAutoJoin() {
    setCanAutoJoin(false);
  }, []);

  const launchZoomApp = useCallback(
    function launchZoomApp(options: ZoomMeetingLaunchOptions) {
      if (isOpen) return;
      open(options);
    },
    [isOpen, open]
  );

  const closeZoomApp = useCallback(
    function closeZoomPip() {
      close();
    },
    [close]
  );

  useEffect(() => {
    if (pipMode === PipContainerMode.FULL_SCREEN) {
      setContainer(pipRef.current?.bodyRef.current ?? null);
    } else {
      setContainer(document.body);
    }
  }, [pipMode, pipRef]);

  const contextValue = useMemo(
    function getContextValue(): ZoomMeetingContextType {
      return {
        appRef,
        canAutoJoin,
        closeZoomApp,
        container,
        disableAutoJoin,
        launchZoomApp,
        pipId,
        pipRef,
        pipMode,
        setPipMode,
        get state() {
          return (
            getContextData() || {
              classId: '',
              courseId: '',
              isOpen: false,
            }
          );
        },
        updateState: updateContextData,
      };
    },
    [
      canAutoJoin,
      closeZoomApp,
      container,
      disableAutoJoin,
      getContextData,
      launchZoomApp,
      pipId,
      pipMode,
      pipRef,
      updateContextData,
    ]
  );

  return { contextValue };
}
