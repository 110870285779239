import { styled } from '@mui/material';

import { pipContainerClasses } from '../constants';

export const Root = styled('div')(({ theme }) => ({
  position: 'fixed',

  [`&.${pipContainerClasses.modePip}`]: {
    inset: 0,
    zIndex: theme.zIndex.pip,
    pointerEvents: 'none',
  },

  [`& .${pipContainerClasses.container}`]: {
    pointerEvents: 'all',
  },

  [`&.${pipContainerClasses.modePip} .${pipContainerClasses.container}`]: {
    padding: theme.spacing(0.5),
    zIndex: theme.zIndex.pip,
  },
}));
