import { RefObject } from 'react';

export interface PipSize {
  width: number;
  height: number;
}

export enum PipContainerMode {
  PIP = 'pip',
  EMBEDDED = 'embedded',
  FULL_SCREEN = 'full_screen',
}

export interface PipContainerInstance {
  parentRef: RefObject<HTMLDivElement>;
  bodyRef: RefObject<HTMLDivElement>;
  exitFullscreen(): void;
  /**
   * Tries to change the current mode of pip-container, if could
   * not change the mode then the returned promise is resolved to `false`.
   *
   * Failure cases:
   *
   * 1. Browser permission
   *
   *     Rejected by browser as user has not given persion to browser
   *     for fullscreen mode.
   *
   * 2. Embed target not found
   *
   *     If requested embedded mode but the target element is not
   *     found in the DOM or is yet to render.
   *
   * @param mode requested mode
   */
  requestModeChange(mode: PipContainerMode): Promise<boolean>;
  resetPosition(): void;
  resetSize(): void;
}

export enum PipContainerType {
  MEDIA_PLAYER = 'media_player',
  ZOOM_MEETING = 'zoom_meeting',
}

export interface BasePipContainerData {
  id: string;
  type: PipContainerType;
}

export interface PipContainerData extends BasePipContainerData {
  isOpen: boolean;
}

export interface PipContainerState {
  byId: Record<string, PipContainerData | undefined>;
}

export interface PipContainerContextValue extends PipContainerState {
  open: <T extends BasePipContainerData = BasePipContainerData>(data: T) => void;
  close: (containerId: string) => void;
  getPipContainerData: <T extends PipContainerData = PipContainerData>(containerId: string) => T | undefined;
  getRefById: (id: string) => RefObject<PipContainerInstance>;
  updatePipContainerData: <T extends {}>(containerId: string, updates: Partial<T>) => void;
}
