import { Ref, useEffect } from 'react';

import { useForwardedRef } from '../../utils/hooks';

export interface VMProps {
  onToggleFullscreen: (isFullscreen: boolean) => void;
}

export default function usePipBody({ onToggleFullscreen }: VMProps, ref: Ref<HTMLDivElement>) {
  const rootRef = useForwardedRef(ref);

  useEffect(
    function watchFullscreenChange() {
      const element = rootRef.current;

      if (!element) return;

      const handleChange = () => onToggleFullscreen(document.fullscreenElement === element);

      element.addEventListener('fullscreenchange', handleChange);

      return () => {
        element.removeEventListener('fullscreenchange', handleChange);
      };
    },
    [onToggleFullscreen, rootRef]
  );

  return { rootRef };
}
