import { Course } from '../../db/courses/types';
import { unix } from '../../utils/datetime';

export function getIsPaymentRequiredToProceed({ course }: { course: Course | undefined }) {
  if (!course) return true;

  const { cost, hasPaid, payTill } = course.payment;

  if (cost <= 0 || hasPaid) return false;

  const currentTime = unix();
  const isTrialPeriodOver = payTill < currentTime;

  return isTrialPeriodOver;
}
