import { styled } from '@mui/material';

import { pipContainerClasses } from '../constants';

export const Root = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  height: '100%',

  [`.${pipContainerClasses.modePip} &`]: {
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
  },
}));
