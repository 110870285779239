import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import MoveIcon from '@mui/icons-material/OpenWith';

import Tooltip from '../../../components/Tooltip';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import ExternalLinkIcon from '../../../icons/ExternalLinkIcon';
import PipContainer from '../../../pip-container';
import { CourseRole } from '../../../types';
import AttendanceSection from './AttendanceSection';
import {
  AttendanceGif,
  PipContent,
  PipHeader,
  PipHeaderButton,
  PipStopButton,
  recordInPersonAttendanceClasses,
} from './styles';
import useRecordingInPersonAttendanceVM, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const RecordingInPersonAttendance = ({ ...vmOptions }: Props) => {
  const { t } = useTranslation([i18nNS.CLASS, i18nNS.GLOSSARY]);

  const { isInPip } = vmOptions;
  const {
    currentUser,
    attendanceInProgress,
    attendancePageUrl,
    canSeePip,
    canStopProxyAttendance,
    onStopAttendance: handleStopAttendance,
    timeRemaining,
  } = useRecordingInPersonAttendanceVM(vmOptions);

  if (currentUser.role === CourseRole.STUDENT) return null;

  if (!attendanceInProgress) return null;

  const { present } = attendanceInProgress;

  if (isInPip && canSeePip) {
    return (
      /**
       * we manage different components for pip and embedded mode
       * so, we do not need embedTargetSelector. But it is a required prop, so passing an empty string
       */
      <PipContainer id="in_person_attendance_pip" embedTargetSelector="" disableResize minHeight={205}>
        <PipHeader>
          <Tooltip title={t('move', { ns: i18nNS.GLOSSARY })}>
            <PipHeaderButton
              size="small"
              className={recordInPersonAttendanceClasses.pipHeaderButtonDragHandle}
              startIcon={<MoveIcon aria-hidden />}
            />
          </Tooltip>
          <Tooltip title={t('go_to_attendance_page', { ns: i18nNS.CLASS })}>
            <PipHeaderButton
              size="small"
              startIcon={<ExternalLinkIcon aria-hidden />}
              component={Link}
              to={attendancePageUrl}
            />
          </Tooltip>
        </PipHeader>
        <PipContent>
          <Typography variant="h6Bold" color="grey.800">
            {t('attendance_in_progress', { ns: i18nNS.CLASS })}
          </Typography>
          <AttendanceGif />
          <Typography
            variant="h6Bold"
            color="warning.800"
            className={recordInPersonAttendanceClasses.alignCenter}
          >
            {t('N_sec', { ns: i18nNS.CLASS, time: timeRemaining })}
          </Typography>
          <Typography variant="paragraphRegular" color="grey.800">
            {t('N_students_marked_present', {
              ns: i18nNS.CLASS,
              count: present > -1 ? present : 0,
            })}
          </Typography>
          <PipStopButton
            variant="text"
            onClick={handleStopAttendance}
            disabled={!canStopProxyAttendance}
            aria-label={t('click_to_stop_in_person_attendance', { ns: i18nNS.CLASS })}
          >
            {t('stop', { ns: i18nNS.GLOSSARY })}
          </PipStopButton>
        </PipContent>
      </PipContainer>
    );
  }

  return (
    <AttendanceSection
      attendanceInProgress={attendanceInProgress}
      timeRemaining={timeRemaining}
      canStopAttendance={canStopProxyAttendance}
      onStopAttendance={handleStopAttendance}
    />
  );
};

export default RecordingInPersonAttendance;
