import { createContext, createRef } from 'react';

import { initialPipContainerState } from './state';
import { PipContainerContextValue } from './types';

export const initialPipContainerContextValue: PipContainerContextValue = {
  ...initialPipContainerState,
  open: () => {},
  close: () => {},
  getPipContainerData: () => undefined,
  getRefById: () => createRef(),
  updatePipContainerData: () => {},
};

const PipContainerContext = createContext<PipContainerContextValue>(initialPipContainerContextValue);

export default PipContainerContext;
