import { Ref, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Rnd, RndDragCallback, RndResizeCallback } from 'react-rnd';

import { PipContainerInstance, PipContainerMode } from '../types';
import {
  getInitialPosition,
  getInitialSize,
  savePipDimensions,
  setEmbeddedModeDimensions,
  setPipModeDimensions,
} from './helpers';

export interface VMProps {
  /**
   * If provided then resizing will done with maintaining the
   * aspect-ratio, otherwise the resize can be done freely.
   *
   * NOTE: this ratio does not include header and footer heights.
   */
  aspectRatio?: number;
  /**
   * disable resizing
   * @default false
   */
  disableResize?: boolean;
  /**
   * CSS selector of the html element that will be used to
   * be followed and covered by this pip-container in
   * `embedded-mode`, if the target is not found then fallback
   * to `pip-mode`.
   */
  embedTargetSelector: string;
  /**
   * This height is not added in aspect-ratio calculation
   * of pip-container
   * @default 0
   */
  footerHeight?: number;
  /**
   * This height is not added in aspect-ratio calculation
   * of pip-container
   * @default 0
   */
  headerHeight?: number;
  id: string;
  /**
   * Maximum allowed height
   * @default window.innerHeight
   */
  maxHeight?: number;
  /**
   * Maximum allowed width
   * @default window.innerWidth
   */
  maxWidth?: number;
  /**
   * Minimum allowed height
   * @default 275
   */
  minHeight?: number;
  /**
   * Minimum allowed width
   * @default 360
   */
  minWidth?: number;
  onModeChange?: (mode: PipContainerMode) => void;
}

const usePipContainerVM = (
  {
    aspectRatio,
    embedTargetSelector,
    disableResize: _disableResize = false,
    footerHeight = 0,
    headerHeight = 0,
    id,
    maxHeight,
    maxWidth,
    minHeight = 275,
    minWidth = 360,
    onModeChange,
  }: VMProps,
  ref: Ref<PipContainerInstance>
) => {
  const rndRef = useRef<Rnd>(null);

  const parentRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  const embedTarget = useRef<HTMLElement | null>(null);
  const embedTimerRef = useRef<NodeJS.Timer | null>(null);

  // used in embedded mode
  const updateParentPositionCallback = useRef<null | (() => any)>(null);

  const pipModeRequestAnimationFrameId = useRef<number | null>(null);

  const [{ mode, isFullscreen }, setMode] = useState<{
    isFullscreen: boolean;
    mode: PipContainerMode.PIP | PipContainerMode.EMBEDDED;
  }>({
    isFullscreen: false,
    mode: PipContainerMode.PIP,
  });

  const lockAspectRatioExtraHeight = useMemo(() => headerHeight + footerHeight, [footerHeight, headerHeight]);

  const lockAspectRatioExtraWidth = 0;

  const [position, setPosition] = useState({
    x: 0,
    y:
      window.innerHeight -
      getInitialSize({
        aspectRatio,
        id,
        lockAspectRatioExtraHeight,
        lockAspectRatioExtraWidth,
        minHeight,
        minWidth,
      }).height,
  });

  const [size, setSize] = useState(
    getInitialSize({
      aspectRatio,
      id,
      lockAspectRatioExtraHeight,
      lockAspectRatioExtraWidth,
      minHeight,
      minWidth,
    })
  );

  const resetPosition = useCallback(() => {
    if (mode !== PipContainerMode.PIP) return;

    if (!parentRef.current) return;

    const size = getInitialSize({
      aspectRatio,
      id,
      lockAspectRatioExtraHeight,
      lockAspectRatioExtraWidth,
      minHeight,
      minWidth,
      skipLocalStorage: true,
    });

    const position = getInitialPosition({
      parent: parentRef.current,
      size,
    });

    setPosition(position);
  }, [mode, aspectRatio, id, lockAspectRatioExtraHeight, minHeight, minWidth]);

  const resetSize = useCallback(() => {
    if (mode !== PipContainerMode.PIP) return;

    const size = getInitialSize({
      aspectRatio,
      id,
      lockAspectRatioExtraHeight,
      lockAspectRatioExtraWidth,
      minHeight,
      minWidth,
      skipLocalStorage: true,
    });

    setSize(size);

    savePipDimensions(id, size);
  }, [mode, aspectRatio, id, lockAspectRatioExtraHeight, minHeight, minWidth]);

  const disableDragging = useMemo(() => mode !== PipContainerMode.PIP, [mode]);
  const disableResize = useMemo(
    () => _disableResize || mode !== PipContainerMode.PIP,
    [_disableResize, mode]
  );

  const lockAspectRatio = useMemo(() => {
    if (mode !== PipContainerMode.PIP) return false;
    if (aspectRatio === undefined) return false;
    return aspectRatio;
  }, [aspectRatio, mode]);

  const handleDragStop: RndDragCallback = useCallback((e, data) => {
    setPosition({ x: data.x, y: data.y });
  }, []);

  const handleResize: RndResizeCallback = useCallback(
    (e, direction, ref, delta, position) => {
      const size = { height: ref.offsetHeight, width: ref.offsetWidth };
      setSize(size);
      savePipDimensions(id, size);
      setPosition(position);
    },
    [id]
  );

  const requestFullScreen = useCallback(async () => {
    const bodyElement = bodyRef.current;
    if (!bodyElement) return Promise.resolve(false);
    try {
      await bodyElement.requestFullscreen();
      return true;
    } catch (e) {
      return false;
    }
  }, []);

  const removeEmbeddedModeEventListeners = useCallback(() => {
    const updateParentPosition = updateParentPositionCallback.current;

    if (updateParentPosition) {
      window.removeEventListener('scroll', updateParentPosition, { capture: true });
      window.removeEventListener('resize', updateParentPosition);
    }
  }, []);

  const setEmbeddedMode = useCallback(
    (target: HTMLElement) => {
      setMode({ isFullscreen: false, mode: PipContainerMode.EMBEDDED });
      embedTarget.current = target;

      const updateParentPosition = () => {
        const target = embedTarget.current;
        const parent = parentRef.current;
        const rnd = rndRef.current;

        if (!target || !parent || !rnd) return;

        setEmbeddedModeDimensions({
          aspectRatio,
          lockAspectRatioExtraHeight,
          lockAspectRatioExtraWidth,
          parent,
          rnd,
          target,
        });
      };

      // remove previous event listeners
      removeEmbeddedModeEventListeners();
      updateParentPosition();

      updateParentPositionCallback.current = updateParentPosition;
      window.addEventListener('scroll', updateParentPosition, { capture: true });
      window.addEventListener('resize', updateParentPosition);
    },
    [aspectRatio, lockAspectRatioExtraHeight, removeEmbeddedModeEventListeners]
  );

  const requestEmbeddedMode = useCallback(() => {
    const stopEmbedTimer = () => {
      const timer = embedTimerRef.current;
      if (!timer) return;
      clearInterval(timer);
      embedTimerRef.current = null;
    };

    stopEmbedTimer(); // stop previously running interval, if any

    return new Promise<boolean>((resolve) => {
      const MAX_RETRIES = 50;
      let attempts = 0;

      const timer = setInterval(() => {
        const target = document.querySelector<HTMLDivElement>(embedTargetSelector);

        if (target) {
          stopEmbedTimer();
          setEmbeddedMode(target);
          return resolve(true);
        }

        if (attempts > MAX_RETRIES) {
          stopEmbedTimer();
          console.warn(`${embedTargetSelector} not found!`);
          return resolve(false);
        }

        attempts++;
      }, 500);

      embedTimerRef.current = timer;
    });
  }, [embedTargetSelector, setEmbeddedMode]);

  const setPipMode = useCallback(() => {
    setMode({ isFullscreen: false, mode: PipContainerMode.PIP });

    const parent = parentRef.current;
    const rnd = rndRef.current;
    if (!parent || !rnd) return;

    pipModeRequestAnimationFrameId.current = setPipModeDimensions({ parent, rnd, size });
  }, [size]);

  const requestModeChange = useCallback(
    (mode: PipContainerMode) => {
      removeEmbeddedModeEventListeners();

      if (mode === PipContainerMode.FULL_SCREEN) {
        return requestFullScreen();
      }

      if (mode === PipContainerMode.EMBEDDED) {
        return requestEmbeddedMode();
      }

      setPipMode();
      return Promise.resolve(true);
    },
    [removeEmbeddedModeEventListeners, requestEmbeddedMode, requestFullScreen, setPipMode]
  );

  const exitFullscreen = useCallback(function exitFullscreen() {
    document.exitFullscreen();
  }, []);

  const handleToggleFullscreen = useCallback(function handleToggleFullscreen(isFullscreen: boolean) {
    setMode(({ mode }) => ({ mode, isFullscreen }));
  }, []);

  useImperativeHandle<PipContainerInstance, PipContainerInstance>(
    ref,
    () => ({
      parentRef,
      bodyRef,
      exitFullscreen,
      requestModeChange,
      resetPosition,
      resetSize,
    }),
    [exitFullscreen, requestModeChange, resetPosition, resetSize]
  );

  useEffect(
    function setInitialPosition() {
      // hack to wait for parent to be rendered
      const timer = setInterval(() => {
        if (!parentRef.current) return;

        const size = getInitialSize({
          aspectRatio,
          id,
          lockAspectRatioExtraHeight,
          lockAspectRatioExtraWidth,
          minHeight,
          minWidth,
        });

        clearInterval(timer);

        setPosition(
          getInitialPosition({
            parent: parentRef.current,
            size,
          })
        );
      }, 100);

      return () => {
        clearInterval(timer);
      };
    },
    [aspectRatio, id, lockAspectRatioExtraHeight, lockAspectRatioExtraWidth, minHeight, minWidth]
  );

  useEffect(
    function handleUnmount() {
      return removeEmbeddedModeEventListeners;
    },
    [removeEmbeddedModeEventListeners]
  );

  useEffect(
    function notifyModeChange() {
      if (isFullscreen) {
        onModeChange?.(PipContainerMode.FULL_SCREEN);
      } else {
        onModeChange?.(mode);
      }
    },
    [isFullscreen, mode, onModeChange]
  );

  useEffect(
    function onExitPipMode() {
      if (mode === PipContainerMode.PIP) return;

      const requestAnimationFrameId = pipModeRequestAnimationFrameId.current;
      if (!requestAnimationFrameId) return;

      window.cancelAnimationFrame(requestAnimationFrameId);
    },
    [mode]
  );

  return {
    bodyRef,
    disableDragging,
    disableResize,
    footerHeight,
    handleDragStop,
    handleResize,
    handleToggleFullscreen,
    headerHeight,
    isFullscreen,
    lockAspectRatio,
    lockAspectRatioExtraHeight,
    lockAspectRatioExtraWidth,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    mode,
    parentRef,
    position,
    requestEmbeddedMode,
    requestFullScreen,
    rndRef,
    setPipMode,
    size,
  };
};

export default usePipContainerVM;
