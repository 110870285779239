import { FC } from 'react';

import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';

import PipContainerProvider from '../pip-container/Provider';
import LayoutContextProvider from '../shared/Layout/Context';
import { useTheme } from '../styles/theme';
import BreadcrumbContextProvider from './BreadcrumbProvider';
import NotificationProvider from './NotificationProvider';
import StripeProviders from './StripeProvider';
import TourProvider from './TourProvider';

const GlobalProvider: FC = ({ children }) => {
  const theme = useTheme();

  return (
    <StripeProviders>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LayoutContextProvider>
            <BreadcrumbContextProvider>
              <TourProvider>
                <PipContainerProvider>{children}</PipContainerProvider>
              </TourProvider>
            </BreadcrumbContextProvider>
            <NotificationProvider />
          </LayoutContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </StripeProviders>
  );
};

export default GlobalProvider;
