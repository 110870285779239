import { PipContainerMode } from '../../pip-container';
import { useZoomMeetingContext } from '../ZoomMeetingContext/hooks';

export default function useZoomAppHeaderVM() {
  const { container, pipRef, pipMode } = useZoomMeetingContext();

  const requestFullScreen = () => {
    return pipRef.current?.requestModeChange(PipContainerMode.FULL_SCREEN);
  };

  const exitFullscreen = () => {
    pipRef.current?.exitFullscreen();
  };

  const handleMinimise = async () => {
    pipRef.current?.resetPosition();
    pipRef.current?.resetSize();
  };

  const handlePopOut = () => {
    return pipRef.current?.requestModeChange(PipContainerMode.PIP);
  };

  const requestEmbeddedMode = async () => {
    return pipRef.current?.requestModeChange(PipContainerMode.EMBEDDED);
  };

  return {
    container,
    exitFullscreen,
    handleMinimise,
    handlePopOut,
    pipMode,
    requestEmbeddedMode,
    requestFullScreen,
  };
}
