import { createRef, ReactNode, RefObject, useCallback, useMemo, useReducer, useRef } from 'react';

import { PipContainerInstance } from '.';
import PipContainerContext from './Context';
import pipContainerStateSlice, { initialPipContainerState, pipContainerAction } from './state';
import { BasePipContainerData, PipContainerContextValue, PipContainerData } from './types';

const usePipContainerProvider = () => {
  const refMap = useRef(new Map<string, RefObject<PipContainerInstance>>());

  const [state, dispatch] = useReducer(pipContainerStateSlice.reducer, initialPipContainerState);

  const getRefById = useCallback((id: string) => {
    let ref = refMap.current.get(id);
    if (!ref) {
      ref = createRef();
      refMap.current.set(id, ref);
    }
    return ref;
  }, []);

  const open = useCallback(<T extends BasePipContainerData>(data: T) => {
    dispatch(pipContainerAction.open(data));
  }, []);

  const close = useCallback((containerId: string) => {
    dispatch(pipContainerAction.close({ containerId }));
  }, []);

  const getPipContainerData = useCallback(
    <T extends PipContainerData>(containerId: string) => {
      return state.byId[containerId] as T | undefined;
    },
    [state.byId]
  );

  const updatePipContainerData = useCallback(<T extends {}>(containerId: string, updates: Partial<T>) => {
    dispatch(pipContainerAction.updatePipContainerData({ containerId, updates }));
  }, []);

  return useMemo<PipContainerContextValue>(
    () => ({
      ...state,
      open,
      close,
      getPipContainerData,
      getRefById,
      updatePipContainerData,
    }),
    [close, getPipContainerData, getRefById, open, state, updatePipContainerData]
  );
};

interface Props {
  children: ReactNode;
}

const PipContainerProvider = ({ children }: Props) => {
  const value = usePipContainerProvider();
  return <PipContainerContext.Provider value={value}>{children}</PipContainerContext.Provider>;
};

export default PipContainerProvider;
