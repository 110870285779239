import { Stack, StackProps, styled } from '@mui/material';

import AcadlyNewCommentsChip from './NewCommentsChip';

export const Root = styled((props: StackProps) => <Stack direction="column" {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.background.level1,
  overflow: 'hidden',
}));

export const CommentsScrollManagerWrapper = styled('div')(({ theme }) => ({
  flex: 1,
  overflow: 'hidden',
  position: 'relative',
}));

export const NewCommentsChip = styled(AcadlyNewCommentsChip)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: 12,
  transform: 'translateX(-50%)',
}));
