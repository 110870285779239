import { createContext, createRef, ReactNode } from 'react';

import { PipContainerMode } from '../../pip-container';
import { ZoomMeetingContextType } from './types';
import useZoomMeetingContextProviderVM from './vm';

const noOp = () => {};

const initialValue: ZoomMeetingContextType = {
  appRef: createRef(),
  canAutoJoin: true,
  closeZoomApp: noOp,
  container: document.body,
  disableAutoJoin: noOp,
  launchZoomApp: noOp,
  pipId: '',
  pipRef: createRef(),
  pipMode: PipContainerMode.PIP,
  setPipMode: noOp,
  state: {
    classId: '',
    courseId: '',
    isOpen: false,
  },
  updateState: noOp,
};

export const ZoomMeetingContext = createContext(initialValue);

interface Props {
  children: ReactNode;
}

const ZoomMeetingContextProvider = ({ children }: Props) => {
  const { contextValue } = useZoomMeetingContextProviderVM();
  return <ZoomMeetingContext.Provider value={contextValue}>{children}</ZoomMeetingContext.Provider>;
};

export default ZoomMeetingContextProvider;
