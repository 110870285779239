import { ReactNode } from 'react';

import { Root } from './styles';

interface Props {
  className?: string;
  children: ReactNode;
}

const PipHeader = ({ className, children }: Props) => {
  return <Root className={className}>{children}</Root>;
};

export default PipHeader;
