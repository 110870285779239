import { styled } from '@mui/material';

import Tag, { Props as TagProps } from '../../../../../../../../components/Tag/index';
import UserCell from '../../../../../../../../components/UserCell/index';
import { userCellClasses } from '../../../../../../../../components/UserCell/styles';

export const commentCreatorClasses = {
  ...userCellClasses,
};

export const StyledUserCell = styled(UserCell)(({ theme }) => ({
  padding: 0,
  overflow: 'hidden',
  [`& .${commentCreatorClasses.avatar}`]: {
    width: 40,
    height: 40,
  },
  [`& .${commentCreatorClasses.content}`]: {
    overflow: 'hidden',
  },
  [`& .${commentCreatorClasses.subtitle}`]: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const Role = styled((props: DistributiveOmit<TagProps, 'color'>) => (
  <Tag variant="outlined" {...props} color="success" />
))(({ theme }) => ({
  alignSelf: 'flex-start',
}));
