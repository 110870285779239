import urls from '../../app/urls';
import { format } from '../../utils/datetime';
import request from '../../utils/request';
import { CourseBook } from '../books/types';
import { CourseLink } from '../links/types';
import { API } from '../shared/api-responses';
import { CourseRole, SuggestedActivityToPlural, SuggestedActivityType } from '../shared/types';
import { CourseTopic, TopicType } from '../topics/types';
import {
  AddCourseStudentsPayload,
  AddCourseTeamMemberPayload,
  AddWeeklySchedulePayload,
  CourseFile,
  CreateCoursePayload,
  EditCourseDescriptionPayload,
  ExportActivityGradesPayload,
  ExportAllGradesPayload,
  FetchStudentAveragesForCourseTeamPayload,
  FetchSuggestedActivityDataPayload,
  InitializeCoursePayload,
  JoinCourseByJoinCodeRequest,
  PublishCourseSchedulePayload,
  ReinstateRemovedStudentPayload,
  SetCourseEndDatePayload,
  SetCourseStartDatePayload,
  UseSuggestedActivityPayload,
} from './types';

export async function getAvailableSlots() {
  const { data } = await request.get<API.GetAvailableSlotsResponse>(urls.getAvailableSlots);
  return data;
}

export async function getCoursePurchaseCost({
  type,
  numCourses,
  numStudents,
}: API.GetCoursePurchaseCostRequest) {
  const { data } = await request.get<API.GetCoursePurchaseCostResponse>(
    urls.getCoursePurchaseCost(type, numCourses, numStudents)
  );
  return data;
}

export async function getInstructorPurchaseIntent(payload: API.GetInstructorPurchaseIntentRequest) {
  const { data } = await request.post<API.GetInstructorPurchaseIntentResponse>(
    urls.getInstructorPurchaseIntent,
    payload
  );
  return data;
}

export async function confirmInstructorPaymentStatus(payload: API.ConfirmInstructorPaymentStatusRequest) {
  const { data } = await request.post<API.ConfirmInstructorPaymentStatusResponse>(
    urls.confirmInstructorPaymentStatus,
    payload
  );
  return data;
}

export async function getPurchaseCourseIntent({ courseId, ...payload }: API.GetPurchaseCourseIntentRequest) {
  const { data } = await request.post<API.GetPurchaseCourseIntentResponse>(
    urls.getPurchaseCourseIntent,
    payload,
    { headers: { courseId } }
  );
  return data;
}

export async function updateCoursePurchaseStatus({
  courseId,
  ...payload
}: API.UpdateCoursePurchaseStatusRequest) {
  const { data } = await request.post<API.UpdateCoursePurchaseStatusResponse>(
    urls.updateCoursePurchaseStatus,
    payload,
    { headers: { courseId } }
  );
  return data;
}

export async function createCourse(payload: CreateCoursePayload) {
  const { isPro, ...rest } = payload;
  const { data } = await request.post<API.CreateCourseResponse>(
    isPro ? urls.createProCourse : urls.createBasicCourse,
    rest
  );

  return data;
}

export async function getCoursePurchaseCouponStatus() {
  const { data } = await request.get<API.GetCoursePurchaseCouponStatusResponse>(
    urls.getCoursePurchaseCouponStatus
  );
  return data;
}

export async function redeemCoupon(payload: API.RedeemCouponRequest) {
  const { data } = await request.post<API.RedeemCouponResponse>(urls.redeemCoupon, payload);
  return data;
}

export async function getAdminCourses() {
  const { data } = await request.get<API.GetAdminCoursesResponse>(urls.getAdminCourses);
  return data;
}

export async function deleteCourse({ courseId }: API.DeleteCourseRequest) {
  await request.delete(urls.deleteCourse, {
    headers: { courseId },
  });
}

export async function getBlueprintCourses() {
  const { data } = await request.get<API.FetchBlueprintCoursesResponse>(urls.fetchBlueprintCourses);
  return data;
}

export async function getBlueprintCoursePreview({ courseId }: API.FetchBlueprintCoursePreviewRequest) {
  const {
    data: { preview },
  } = await request.get<API.FetchBlueprintCoursePreviewResponse>(urls.blueprintCoursePreview(courseId));

  const readingList = {
    books: preview.readingList.books.map((book) => {
      const { _id, recommended, ...rest } = book;
      return {
        ...rest,
        id: _id,
        isRecommended: Boolean(recommended),
      } as CourseBook;
    }),
    links: preview.readingList.links.map((link) => {
      const { _id, ...rest } = link;
      return {
        ...rest,
        id: _id,
      } as CourseLink;
    }),
    files: preview.readingList.files.map((file) => {
      const { _id, ...rest } = file;
      return {
        ...rest,
        id: _id,
      } as CourseFile;
    }),
  };

  const syllabus = (() => {
    const { _id, ...rest } = preview.syllabus;
    if (!_id) return undefined;

    return {
      ...rest,
      id: _id,
    } as CourseFile;
  })();

  const topics: CourseTopic[] = [];

  for (const topic of preview.topics) {
    const { _id, topics: subTopics } = topic;

    for (const subTopic of subTopics) {
      if (subTopic.topicId === _id) {
        topics.push({
          type: TopicType.PARENT,
          id: _id,
          title: subTopic.title,
          books: [],
          links: [],
          subTopics: [],
        });
      } else {
        topics.push({
          type: TopicType.SUB_TOPIC,
          id: subTopic.topicId,
          parentId: _id,
          title: subTopic.title,
          books: [],
          links: [],
        });
      }
    }
  }

  return {
    activities: preview.activities,
    description: preview.description,
    readingList,
    syllabus,
    topics,
  };
}

export async function initializeCourse({ isCopied, copyOf, toCopy }: InitializeCoursePayload) {
  const payload: API.InitializeCourseRequest = {
    isCopied: isCopied ? 1 : 0,
    copyOf,
    toCopy: {
      description: toCopy.description ? 1 : 0,
      readingList: toCopy.readingList ? 1 : 0,
      topics: toCopy.topics ? 1 : 0,
      syllabus: toCopy.syllabus ? 1 : 0,
    },
  };

  const { data } = await request.put<API.InitializeCourseResponse>(urls.initializeCourse, payload);
  return data;
}

export async function getTimezones() {
  const { data } = await request.get<API.FetchTimezonesResponse>(urls.getTimezones);
  return data;
}

export async function setTimezone(payload: API.SetTimezoneRequest) {
  await request.post<unknown>(urls.setTimezone, payload);
}

export async function addWeeklySchedule({ isOnlineMeeting, ...weeklySchedule }: AddWeeklySchedulePayload) {
  const payload: API.AddWeeklyScheduleRequest = {
    ...weeklySchedule,
    isOnlineMeeting: isOnlineMeeting ? 1 : 0,
  };
  await request.post<unknown>(urls.addWeeklySchedule, payload);
}

export async function removeWeeklySchedule(payload: API.RemoveWeeklyScheduleRequest) {
  await request.delete<unknown>(urls.removeWeeklySchedule, { data: payload });
}

export async function checkNumScheduledClassesLimit() {
  const { data } = await request.get<API.CheckNumScheduledClassesLimitResponse>(
    urls.checkNumScheduledClassesLimit
  );
  return data;
}

export async function publishCourseSchedule({ enrollmentBy }: PublishCourseSchedulePayload) {
  const payload: API.PublishCourseScheduleRequest = {
    enrolment: enrollmentBy,
  };

  const { data } = await request.put<API.PublishCourseScheduleResponse>(urls.publishCourseSchedule, payload);
  return data;
}

export async function publishCourse() {
  const { data } = await request.put<API.PublishCourseResponse>(urls.publishCourse);
  return data;
}

export async function getMyCourses() {
  const { data } = await request.get<API.FetchMyCoursesResponse>(urls.myCourses);
  return data;
}

export async function getArchivedCourses() {
  const { data } = await request.get<API.FetchArchivedCoursesResponse>(urls.archivedCourses);
  return data;
}

export async function archiveCourse() {
  await request.put(urls.archiveCourse);
}

export async function getCourseDetails() {
  const { data } = await request.get<API.FetchCourseDetailsResponse>(urls.courseDetails);
  return data;
}

export async function joinCourseByJoincode(joinCode: JoinCourseByJoinCodeRequest) {
  const response = await request.post<API.JoinCourseByJoinCodeResponse>(urls.joinCourse, joinCode);
  return response.data;
}

export async function createProDemoCourse(payload: API.CreateProDemoCourseRequest) {
  const { data } = await request.post<API.CreateProDemoCourseResponse>(urls.createProDemoCourse, payload);
  return data;
}

export async function createBasicDemoCourse(payload: API.CreateBasicDemoCourseRequest) {
  const { data } = await request.post<API.CreateBasicDemoCourseResponse>(urls.createBasicDemoCourse, payload);
  return data;
}

export async function getCoursesToCopyActivity() {
  const { data } = await request.get<API.GetCoursesToCopyActivityResponse>(urls.getCoursesToCopyActivity);
  return data;
}

export async function copyActivity(payload: API.CopyActivityRequest) {
  const activityType = SuggestedActivityToPlural[payload.activityType];
  await request.post<API.CopyActivityResponse>(urls.copyActivity, { ...payload, activityType });
}

export async function getCourseInfo() {
  const { data } = await request.get<API.FetchCourseInfoResponse>(urls.courseInfo);
  return data;
}

export async function publishCourseInfo() {
  await request.get(urls.publishCourseInfo);
}

export async function setCourseStartDate({ startDate }: SetCourseStartDatePayload) {
  const payload: API.SetCourseStartDateRequest = { startDate: format(startDate, 'yyyy-MM-dd') };
  const { data } = await request.post<API.SetCourseStartDateResponse>(urls.setCourseStartDate, payload);
  return data;
}

export async function setCourseEndDate({ endDate }: SetCourseEndDatePayload) {
  const payload: API.SetCourseEndDateRequest = { endDate: format(endDate, 'yyyy-MM-dd') };
  const { data } = await request.post<API.SetCourseEndDateResponse>(urls.setCourseEndDate, payload);
  return data;
}

export async function getCourseSyllabus() {
  const { data } = await request.get<API.GetCourseSyllabusResponse>(urls.courseTopics);
  return data;
}

export async function editCourseDescription(payload: EditCourseDescriptionPayload) {
  await request.post(urls.editCourseDescription, payload);
}

export async function saveOfficeHours(payload: API.SaveOfficeHoursRequest) {
  await request.post(urls.saveOfficeHours, payload);
}

export async function saveCourseInfoFile(payload: API.SaveCourseInfoFileRequest) {
  const { data } = await request.post<API.SaveCourseInfoFileResponse>(urls.saveCourseInfoFile, payload);
  const file: CourseFile = {
    id: data._id,
    name: payload.generatedFileName,
    originalName: payload.originalFileName,
    extension: payload.fileType,
  };
  return file;
}

export async function removeCourseInfoFile(data: API.RemoveCourseInfoFileRequest) {
  await request.delete(urls.removeCourseInfoFile, { data });
}

export async function saveCourseSyllabusFile(payload: API.SaveCourseSyllabusFileRequest) {
  const { data } = await request.post<API.SaveCourseSyllabusFileResponse>(
    urls.saveCourseSyllabusFile,
    payload
  );
  const file: CourseFile = {
    id: data._id,
    name: payload.generatedFileName,
    originalName: payload.originalFileName,
    extension: payload.fileType,
  };
  return file;
}

export async function addCourseTeamMember(payload: AddCourseTeamMemberPayload) {
  const { data } = await request.post<API.AddCourseTeamMemberResponse>(urls.addCourseTeamMember, payload);
  const { message: _, ...member } = data;
  return member;
}

export async function removeCourseMember(payload: API.RemoveCourseMemberRequest) {
  const { data } = await request.post(urls.removeCourseMember, payload);
  return data;
}

export async function getCourseEnrollments() {
  const { data } = await request.get<API.FetchCourseEnrollmentsResponse>(urls.getCourseEnrollments);
  return data;
}

export async function addCourseStudents({ emailIds }: AddCourseStudentsPayload) {
  const payload: API.AddCourseStudentsRequest = { role: CourseRole.STUDENT, emailIds };
  const { data } = await request.post<API.AddCourseStudentsResponse>(urls.addCourseStudents, payload);
  return data;
}

export async function reinstateRemovedStudent({ studentId }: ReinstateRemovedStudentPayload) {
  const payload: API.ReinstateRemovedStudentRequest = { memberId: studentId };
  await request.post(urls.reinstateRemovedStudent, payload);
}

export async function resendCourseInvites() {
  await request.post(urls.resendCourseInvites);
}

export async function getCourseAveragesForCourseTeam() {
  const { data } = await request.get<API.GetCourseAveragesForCourseTeamResponse>(
    urls.getCourseAveragesForCourseTeam
  );
  return data;
}

export async function getStudentAveragesForCourseTeam({
  studentId,
}: FetchStudentAveragesForCourseTeamPayload) {
  const { data } = await request.get<API.GetStudentAveragesForCourseTeamResponse>(
    urls.getStudentAveragesForCourseTeam(studentId)
  );
  return data;
}

export async function getMyCourseAnalytics() {
  const { data } = await request.get<API.GetMyCourseAnalyticsResponse>(urls.getMyCourseAnalytics);
  return data;
}

export async function getAllSuggestedActivities() {
  const { data } = await request.get<API.GetAllSuggestedActivityResponse>(urls.suggestedActivities);
  return data;
}

export async function getSuggestedActivityData(params: FetchSuggestedActivityDataPayload) {
  const { data } = await request.get<API.FetchSuggestedActivityDataResponse>(urls.suggestedActivityDetails, {
    params,
  });
  return data;
}

export async function useSuggestedActivity(params: UseSuggestedActivityPayload) {
  let payload: API.UseSuggestedActivityRequest;

  if (params.activityType === SuggestedActivityType.ASSIGNMENT) {
    payload = {
      activityId: params.activityId,
      activityType: SuggestedActivityToPlural[params.activityType],
      copyToClass: '',
      toBeDone: '',
    };
  } else {
    payload = {
      activityId: params.activityId,
      activityType: SuggestedActivityToPlural[params.activityType],
      copyToClass: params.copyToClass,
      toBeDone: params.toBeDone,
    };
  }

  await request.post(urls.useSuggestedActivity, payload);
}

export async function hideSuggestedActivity(payload: API.HideSuggestedClassActivityPayload) {
  const activityType = SuggestedActivityToPlural[payload.activityType];
  await request.put(urls.hideSuggestedActivity, { activityId: payload.activityId, activityType });
}

export async function exportActivityGrades({ shouldEmail, ...payload }: ExportActivityGradesPayload) {
  const params: API.ExportActivityGradesRequest = { ...payload, email: shouldEmail ? 1 : 0 };
  const { data } = await request.get<API.ExportActivityGradesResponse>(urls.exportActivityGrades, { params });
  return data;
}

export async function exportAllGrades({ shouldEmail, ...payload }: ExportAllGradesPayload) {
  const params: API.ExportAllGradesRequest = { ...payload, email: shouldEmail ? 1 : 0 };
  const { data } = await request.get<API.ExportAllGradesResponse>(urls.exportAllGrades, { params });
  return data;
}
