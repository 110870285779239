import { createReducer } from '@reduxjs/toolkit';

import { closeClass, fetchClassDetails } from '../classes/actions';
import { getClassRole, getClassTeam } from '../classes/helpers';
import { ClassRole, MeetingStatus, ResolveHandAction } from '../shared/types';
import {
  addressHand,
  addressingHand,
  authorizeZoomApp,
  broadcastStarted,
  broadcastStopped,
  createZoomMeeting,
  deleteClassRecordings,
  endZoomMeetingBroadcast,
  fetchClassRecordings,
  fetchZoomMeetingCredentials,
  fetchZoomMeetingDetails,
  handLowered,
  handRaised,
  handResolved,
  lowerHand,
  meetingDestroyed,
  meetingEnded,
  meetingLaunched,
  meetingStarted,
  participantJoined,
  participantLeft,
  publishClassRecordings,
  raiseHand,
  readyToBroadcast,
  resolveHand,
  startZoomMeetingBroadcast,
  zoomAuthenticated,
} from './actions';
import {
  classRecordingFactory,
  meetingRecordingFactory,
  onlineMeetingFactory,
  recordConfigFactory,
} from './helpers';
import { OnlineMeetings } from './types';

const initialState: OnlineMeetings = {
  zoomAuthorizationData: undefined,
  byClassId: {},
  recordingsByClassId: {},
};

const onlineMeetingReducer = createReducer(initialState, (builder) => {
  builder.addCase(closeClass, (state, action) => {
    const { classId } = action.payload;
    state.recordingsByClassId[classId] = undefined;
  });

  builder.addCase(fetchClassDetails.success, (state, action) => {
    const { courseId, classId, currentUser, autoRecordDefaults, info, recordings, onlineDetails, userData } =
      action.payload;

    state.recordingsByClassId[classId] = classRecordingFactory({
      classId,
      autoRecordDefaults,
      recordings,
      userData,
    });

    if (onlineDetails) {
      const { incharges, assistants } = getClassTeam({ classInchargeSet: 1 }, info);
      state.byClassId[classId] = onlineMeetingFactory({
        classId,
        courseId,
        classRole: getClassRole({ incharges, assistants }, currentUser),
        onlineDetails,
      });
    }
  });

  builder.addCase(authorizeZoomApp.success, (state, action) => {
    const { hasProCourses, isAllowed, isAuthorized } = action.payload;
    state.zoomAuthorizationData = {
      ...action.payload,
      isAllowed: isAllowed === 1,
      hasProCourses: hasProCourses === 1,
      isAuthorized: isAuthorized === 1,
    };
  });

  builder.addCase(zoomAuthenticated, (state, action) => {
    const { classId, autoRecordDefaults } = action.payload;

    const onlineMeeting = state.byClassId[classId];
    if (!onlineMeeting) return;

    onlineMeeting.hasAuthorizedAcadly = true;

    const recording = state.recordingsByClassId[classId];
    if (!recording) return;

    recording.config = recordConfigFactory(autoRecordDefaults);
  });

  builder.addCase(createZoomMeeting.success, (state, action) => {
    const { classId, courseId, onlineDetails, autoRecord } = action.payload;

    state.byClassId[classId] = onlineMeetingFactory({
      classId,
      courseId,
      onlineDetails,
      classRole: ClassRole.INCHARGE,
    });

    const recoding = state.recordingsByClassId[classId];
    if (!recoding) return;

    recoding.config.autoRecord = autoRecord;
  });

  builder.addCase(meetingLaunched, (state, action) => {
    const { classId, hostDetails } = action.payload;

    const onlineMeeting = state.byClassId[classId];
    if (!onlineMeeting) return;

    onlineMeeting.hostUserId = hostDetails.userId;
    onlineMeeting.hostDetails = hostDetails;
    onlineMeeting.status = MeetingStatus.IN_PROGESS;
  });

  builder.addCase(readyToBroadcast, (state, action) => {
    const { classId, currentUser } = action.payload;

    const onlineMeeting = state.byClassId[classId];
    if (!onlineMeeting) return;

    if (onlineMeeting.hostUserId !== currentUser.userId) return;

    onlineMeeting.isReadyToBroadcast = true;
  });

  builder.addCase(startZoomMeetingBroadcast.success, (state, action) => {
    const { classId, meetingId } = action.payload;

    const onlineMeeting = state.byClassId[classId];
    if (onlineMeeting?.meetingId !== meetingId) return;

    onlineMeeting.isBroadcasting = true;
    onlineMeeting.isBroadcastedFromCurrentDevice = true;

    // reset participants
    onlineMeeting.participantsById = {};
  });

  builder.addCase(broadcastStarted, (state, action) => {
    const { classId, meetingId } = action.payload;

    const onlineMeeting = state.byClassId[classId];
    if (!onlineMeeting) return;

    onlineMeeting.meetingId = meetingId;
    onlineMeeting.isBroadcasting = true;
    onlineMeeting.status = MeetingStatus.IN_PROGESS;
  });

  builder.addCase(meetingStarted, (state, action) => {
    const { classId, meetingId } = action.payload;

    const onlineMeeting = state.byClassId[classId];
    if (!onlineMeeting) return;

    onlineMeeting.meetingId = meetingId;
    onlineMeeting.isBroadcasting = true;
    onlineMeeting.status = MeetingStatus.IN_PROGESS;
  });

  builder.addCase(fetchZoomMeetingDetails.success, (state, action) => {
    const { classId, courseId, message, classRole, ...onlineDetails } = action.payload;
    const hasAuthorizedAcadly = state.byClassId[classId]?.hasAuthorizedAcadly;
    state.byClassId[classId] = onlineMeetingFactory({
      classId,
      courseId,
      onlineDetails,
      classRole,
      hasAuthorizedAcadly,
    });
  });

  builder.addCase(fetchZoomMeetingCredentials.success, (state, action) => {
    const { classId, meetingId, meetingPassword, joinId, closeUrl } = action.payload;

    const onlineMeeting = state.byClassId[classId];
    if (!onlineMeeting) return;

    onlineMeeting.closeUrl = closeUrl;
    onlineMeeting.meetingId = meetingId;
    onlineMeeting.joinId = joinId;
    onlineMeeting.password = meetingPassword;
  });

  builder.addCase(participantJoined, (state, action) => {
    const { classId, userId, zoomUserId, role, name, avatar } = action.payload;

    const onlineMeeting = state.byClassId[classId];
    if (!onlineMeeting) return;

    onlineMeeting.participantsById[userId] = {
      userId,
      zoomUserId,
      role,
      name,
      avatar,
      hasRaisedHand: false,
      handRaisedAt: -1,
      beingAddressed: false,
    };
  });

  builder.addCase(raiseHand.success, (state, action) => {
    const { classId, currentUser, raisedAt } = action.payload;

    const participant = state.byClassId[classId]?.participantsById[currentUser.userId];
    if (!participant) return;

    participant.hasRaisedHand = true;
    participant.beingAddressed = false;
    participant.handRaisedAt = raisedAt;
  });

  builder.addCase(handRaised, (state, action) => {
    const { classId, userId, raisedAt } = action.payload;

    const participant = state.byClassId[classId]?.participantsById[userId];
    if (!participant) return;

    participant.hasRaisedHand = true;
    participant.beingAddressed = false;
    participant.handRaisedAt = raisedAt;
  });

  builder.addCase(lowerHand.success, (state, action) => {
    const { classId, currentUser } = action.payload;

    const participant = state.byClassId[classId]?.participantsById[currentUser.userId];
    if (!participant) return;

    participant.hasRaisedHand = false;
    participant.beingAddressed = false;
    participant.handRaisedAt = -1;
  });

  builder.addCase(handLowered, (state, action) => {
    const { classId, userId } = action.payload;

    const participant = state.byClassId[classId]?.participantsById[userId];
    if (!participant) return;

    participant.hasRaisedHand = false;
    participant.beingAddressed = false;
    participant.handRaisedAt = -1;
  });

  builder.addCase(addressHand.success, (state, action) => {
    const { classId, userId } = action.payload;

    const participant = state.byClassId[classId]?.participantsById[userId];
    if (!participant) return;

    participant.beingAddressed = true;
  });

  builder.addCase(addressingHand, (state, action) => {
    const { classId, userId } = action.payload;

    const participant = state.byClassId[classId]?.participantsById[userId];
    if (!participant) return;

    participant.beingAddressed = true;
  });

  builder.addCase(resolveHand.success, (state, action) => {
    const { classId, userId, nextUser } = action.payload;

    const participant = state.byClassId[classId]?.participantsById[userId];

    if (participant) {
      participant.hasRaisedHand = false;
      participant.beingAddressed = false;
      participant.handRaisedAt = -1;
    }

    if (!nextUser) return;

    const nextParticipant = state.byClassId[classId]?.participantsById[nextUser.userId];
    if (!nextParticipant) return;

    nextParticipant.beingAddressed = true;
  });

  builder.addCase(handResolved, (state, action) => {
    const { classId, userId, action: resolveAction } = action.payload;

    const participant = state.byClassId[classId]?.participantsById[userId];

    if (participant) {
      participant.hasRaisedHand = false;
      participant.beingAddressed = false;
      participant.handRaisedAt = -1;
    }

    if (resolveAction !== ResolveHandAction.NEXT) return;

    const { nextUser } = action.payload;

    const nextParticipant = state.byClassId[classId]?.participantsById[nextUser.userId];
    if (!nextParticipant) return;

    nextParticipant.beingAddressed = true;
  });

  builder.addCase(participantLeft, (state, action) => {
    const { classId, userId } = action.payload;

    const onlineMeeting = state.byClassId[classId];
    if (!onlineMeeting) return;

    onlineMeeting.participantsById[userId] = undefined;
  });

  builder.addCase(endZoomMeetingBroadcast.success, (state, action) => {
    const { classId, meetingId } = action.payload;

    const onlineMeeting = state.byClassId[classId];
    if (onlineMeeting?.meetingId !== meetingId) return;

    onlineMeeting.isBroadcasting = false;
    onlineMeeting.isBroadcastedFromCurrentDevice = false;
  });

  builder.addCase(broadcastStopped, (state, action) => {
    const { classId, meetingId } = action.payload;

    const onlineMeeting = state.byClassId[classId];
    if (onlineMeeting?.meetingId !== meetingId) return;

    onlineMeeting.isBroadcasting = false;
    onlineMeeting.status = MeetingStatus.ENDED;
  });

  builder.addCase(meetingEnded, (state, action) => {
    const { classId, meetingId, myRole } = action.payload;

    const onlineMeeting = state.byClassId[classId];
    if (onlineMeeting?.meetingId !== meetingId) return;

    if (myRole === ClassRole.INCHARGE) {
      onlineMeeting.canAutoJoinMeeting = true;
      return;
    }

    onlineMeeting.meetingId = 0;
    onlineMeeting.isBroadcasting = false;
    onlineMeeting.isReadyToBroadcast = false;
    onlineMeeting.isBroadcastedFromCurrentDevice = false;
    onlineMeeting.status = MeetingStatus.ENDED;
    // turn canAutoJoinMeeting on, so that user can join meeting,
    // when meeting host rejoins the meeting
    // or when new meeting is started for the same class
    onlineMeeting.canAutoJoinMeeting = true;
  });

  builder.addCase(meetingDestroyed, (state, action) => {
    const { classId, meetingId } = action.payload;

    const onlineMeeting = state.byClassId[classId];
    if (onlineMeeting?.meetingId !== meetingId) return;

    onlineMeeting.meetingId = 0;
    onlineMeeting.isBroadcasting = false;
    onlineMeeting.isReadyToBroadcast = false;
    onlineMeeting.isBroadcastedFromCurrentDevice = false;
    onlineMeeting.status = MeetingStatus.ENDED;
  });

  builder.addCase(fetchClassRecordings.success, (state, action) => {
    const { classId, recordings } = action.payload;

    const classRecordings = state.recordingsByClassId[classId];
    if (!classRecordings) return;

    classRecordings.byId = {};

    for (const recording of recordings) {
      classRecordings.byId[recording.recordingId] = meetingRecordingFactory(recording);
    }
  });

  builder.addCase(publishClassRecordings.success, (state, action) => {
    const { classId } = action.payload;

    const classRecordings = state.recordingsByClassId[classId];
    if (!classRecordings?.data) return;

    classRecordings.data.isPublished = true;
  });

  builder.addCase(deleteClassRecordings.success, (state, action) => {
    const { classId, filenamesToDelete, recordingId } = action.payload;

    if (!filenamesToDelete.length) return;

    const classRecordings = state.recordingsByClassId[classId];
    if (!classRecordings?.byId) return;

    const recording = classRecordings.byId[recordingId];
    if (!recording) return;

    for (const fileName of filenamesToDelete) {
      recording.filesByName[fileName] = undefined;
    }

    const hasFiles = Object.keys(recording.filesByName).some((name) => Boolean(recording.filesByName[name]));

    if (!hasFiles) {
      // remove whole recording object if no files left
      classRecordings.byId[recordingId] = undefined;
    }
  });
});

export default onlineMeetingReducer;
