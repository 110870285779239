import { createReducer, Dictionary, isAnyOf } from '@reduxjs/toolkit';

import { unix } from '../../utils/datetime';
import { insertByBinarySearch } from '../../utils/helpers';
import { announcementCreated, createAnnouncement } from '../announcements/actions';
import {
  createAssignment,
  deleteAssignment,
  editPublishedAssignment,
  publishAssignment,
} from '../assignments/actions';
import { addCourseBook, removeCourseBook } from '../books/actions';
import { deleteClass } from '../classes/actions';
import {
  createDiscussion,
  deleteDiscussion,
  discussionPublished,
  markDiscussionAsViewed,
  publishDiscussion,
} from '../discussions/actions';
import { addCourseLink, removeCourseLink } from '../links/actions';
import { createPoll, deletePoll, fetchPollDetails, pollPublished, publishPoll } from '../polls/actions';
import {
  approveQuery,
  createQuery,
  deleteQuery,
  markQueryAsViewed,
  queryApproved,
  queryCreated,
} from '../queries/actions';
import { createQuiz, deleteQuiz, fetchQuizDetails, publishQuiz, quizPublished } from '../quizzes/actions';
import {
  createResource,
  deleteResource,
  incrementResourceViews,
  publishResource,
  resourcePublished,
} from '../resources/actions';
import createCommentReducer from '../shared/reducers/comments';
import {
  AnnouncementType,
  CommentContext,
  CourseMemberStatus,
  CourseRole,
  CourseType,
  EnrollmentBy,
  SuggestedActivityToPlural,
  SuggestedActivityType,
  TimelineItemType,
} from '../shared/types';
import { pipeReducers } from '../shared/utils';
import { addCourseTopic, deleteCourseTopic } from '../topics/actions';
import { TopicType } from '../topics/types';
import { University } from '../university/types';
import {
  addCourseStudents,
  addCourseTeamMember,
  addWeeklySchedule,
  archiveCourse,
  closeCourse,
  coursePurchaseSuccessful,
  createCourse,
  deleteCourses,
  editCourseDescription,
  fetchBlueprintCourses,
  fetchCourseAveragesForCourseTeam,
  fetchCourseDetails,
  fetchCourseEnrollments,
  fetchCourseInfo,
  fetchMyCourseAnalytics,
  fetchMyCourses,
  fetchStudentAveragesForCourseTeam,
  initializeCourse,
  joinCourseByJoinCode,
  openCourse,
  publishCourse,
  publishCourseInfo,
  publishCourseSchedule,
  reinstateRemovedStudent,
  removeCourseInfoFile,
  removeCourseMember,
  removeCourseStudent,
  removeWeeklySchedule,
  saveCourseInfoFile,
  saveCourseSyllabusFile,
  saveOfficeHours,
  setCourseEndDate,
  setCourseStartDate,
  setTimezone,
  useSuggestedActivity,
} from './actions';
import { activityPublished, addAnnouncementCaseReducer } from './case-reducers.ts';
import {
  timelineComparer,
  toCourseActivities,
  toCourseEnrollment,
  toCourseFile,
  toCourseSchedule,
  toCourseStatus,
  toCourseTeam,
  toPermissions,
} from './helpers';
import { BlueprintCourse, Course, CourseEnrollment, TimelineItem } from './types';

interface Courses {
  /** current course-id, `null` means not inside a course */
  currentCourse: MongoId | null;
  /** Courses as dictionary of course-id and course-data */
  byId: Dictionary<Course>;
  order: MongoId[];
  archivedOrder: MongoId[];
  blueprintById: Dictionary<BlueprintCourse>;
  blueprintOrder: MongoId[];
  /**
   * Total number of courses + archivedCourses
   * does not include blueprintCourses count
   */
  total: number; // TODO: make it derived state
  universityData: Pick<University, 'hasEnterpriseAccount' | 'integratedWith'> | null;
}

const initialState: Courses = {
  currentCourse: null,
  byId: {},
  order: [],
  archivedOrder: [],
  blueprintById: {},
  blueprintOrder: [],
  total: 0,
  universityData: null,
};

const baseCoursesReducer = createReducer(initialState, (builder) => {
  builder.addCase(openCourse, (state, action) => {
    state.currentCourse = action.payload.courseId;
  });

  builder.addCase(closeCourse, (state) => {
    const courseId = state.currentCourse;
    state.currentCourse = null;

    if (!courseId) return;

    const course = state.byId[courseId];
    if (!course) return;

    // clear course data
    course.timeline = null;
    course.info = null;
    course.syllabus = null;

    course.enrolledStudentsById = null;
    course.invitedStudentsById = null;
    course.removedStudentsById = null;

    course.averages = null;
    course.studentAveragesByStudentId = null;
  });

  builder.addCase(coursePurchaseSuccessful, (state, action) => {
    const { courseId } = action.payload;

    const course = state.byId[courseId];
    if (!course) return;

    course.payment.hasPaid = true;
  });

  builder.addCase(createCourse.success, (state, action) => {
    const data = action.payload;

    const comments: Course['comments'] = {
      isSubscribed: false,
      total: data.activities.numCommentsTotal ?? 0,
      seen: 0,
    };

    const course: Course = {
      id: data.courseId,
      code: data.details.code,
      title: data.details.title,
      schedule: toCourseSchedule(),
      info: null,
      syllabus: null,
      timeline: null,
      myRole: CourseRole.ADMIN,
      team: toCourseTeam(data.team),
      type: data.courseType ?? CourseType.SYSTEM,
      enrollment: toCourseEnrollment(),
      enrolledStudentsById: null,
      invitedStudentsById: null,
      removedStudentsById: null,
      permissions: toPermissions(data),
      payment: {
        cost: 0,
        hasPaid: false,
        payTill: -1,
      },
      comments,
      activities: toCourseActivities(data),
      averages: null,
      studentAveragesByStudentId: null,
      status: toCourseStatus(data.status),
    };

    state.byId[course.id] = course;
    state.order.push(course.id);
    state.total++;
  });

  builder.addCase(deleteCourses.success, (state, action) => {
    const { courseId } = action.payload;
    state.byId[courseId] = undefined;
    state.order = state.order.filter((id) => {
      return id !== courseId;
    });
  });

  builder.addCase(fetchBlueprintCourses.success, (state, action) => {
    state.blueprintById = {};
    state.blueprintOrder = [];

    const { blueprintCourses } = action.payload;

    for (const item of blueprintCourses) {
      const { courseId, code, title, admin, role, totalActivities, archived } = item;

      const blueprintCourse: BlueprintCourse = {
        id: courseId,
        code: code,
        title: title,
        schedule: toCourseSchedule(undefined, item),
        team: toCourseTeam([
          {
            ...admin,
            role: CourseRole.ADMIN,
            status: CourseMemberStatus.ACTIVE,
          },
        ]),
        myRole: role,
        activities: {
          total: totalActivities,
          seen: 0,
          announcements: { published: 0, total: 0 },
          assignments: { published: 0, total: 0 },
          classes: { published: 0, total: 0 },
          discussions: { published: 0, total: 0 },
          polls: { published: 0, total: 0 },
          queries: { published: 0, total: 0, seen: 0, pending: 0 },
          quizzes: { published: 0, total: 0 },
          resources: { published: 0, total: 0 },
        },
        status: toCourseStatus({}, Boolean(archived)),
      };

      state.blueprintById[courseId] = blueprintCourse;
      state.blueprintOrder.push(courseId);
    }
  });

  builder.addCase(initializeCourse.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    if (action.payload.initialized !== 1) return;
    course.status.isInitialized = true;
  });

  builder.addCase(setTimezone.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    course.schedule.timezone = action.payload.timezone;
    course.status.isTimezoneSet = true;
  });

  builder.addCase(addWeeklySchedule.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { day, ...schedule } = action.payload;

    const weeklySchedule = day.map((d) => ({
      ...schedule,
      day: d,
    }));

    course.schedule.weekly.push(...weeklySchedule);
  });

  builder.addCase(removeWeeklySchedule.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { type, day, startTime } = action.payload;

    course.schedule.weekly = course.schedule.weekly.filter(
      (weeklySchedule) =>
        !(
          weeklySchedule.type === type &&
          weeklySchedule.day === day &&
          weeklySchedule.startTime === startTime
        )
    );
  });

  builder.addCase(publishCourseSchedule.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { courseData, courseStatus, enrolmentType } = action.payload;

    course.timeline = courseData
      .map<TimelineItem>((item) => ({
        id: item._id,
        type: item.nodeType,
        dueDateTime: item.details.dueDateTime,
      }))
      .sort(timelineComparer);

    course.status = toCourseStatus(courseStatus, false);

    if (enrolmentType === EnrollmentBy.CODE) {
      course.enrollment = {
        by: enrolmentType,
        code: action.payload.joinCode,
      };
    } else {
      course.enrollment = {
        by: enrolmentType,
      };
    }
  });

  builder.addCase(publishCourse.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    course.status.isLive = true;

    if (course.enrollment.by === EnrollmentBy.INVITATION) return;

    const { joinCode } = action.payload;
    course.enrollment.code = joinCode;
  });

  builder.addCase(fetchMyCourses.success, (state, action) => {
    const {
      archivedCourseData,
      courseData,
      currentUserId,
      userData: userDataMap,
      universityData,
    } = action.payload;

    state.total = 0;
    state.byId = {};
    state.order = [];
    state.archivedOrder = [];

    state.universityData = {
      hasEnterpriseAccount: Boolean(universityData.hasEnterpriseAccount),
      integratedWith: universityData.integratedWith,
    };

    for (const data of courseData) {
      const userData = userDataMap.courses?.[data._id];
      const team = toCourseTeam(data.team);

      const comments: Course['comments'] = {
        isSubscribed: Boolean(userData?.subscribed),
        total: data.activities.numCommentsTotal ?? 0,
        seen: userData?.numCommentsSeen ?? 0,
      };

      const course: Course = {
        id: data._id,
        code: data.details.code,
        title: data.details.title,
        schedule: toCourseSchedule(data.courseTimezone, data.dates),
        info: null,
        syllabus: null,
        timeline: null,
        myRole: userData?.role || CourseRole.STUDENT,
        team,
        type: data.courseType ?? CourseType.SYSTEM,
        enrollment: toCourseEnrollment(data.enrolmentType, data.joinCode),
        enrolledStudentsById: null,
        invitedStudentsById: null,
        removedStudentsById: null,
        permissions: toPermissions(data),
        payment: {
          cost: data.cost,
          hasPaid: Boolean(userData?.hasPaid),
          payTill: userData?.payTill ?? -1,
        },
        comments,
        activities: toCourseActivities(data, userData),
        averages: null,
        studentAveragesByStudentId: null,
        status: toCourseStatus(data.status),
      };

      state.byId[course.id] = course;
      state.order.push(course.id);
      state.total++;
    }

    for (const data of archivedCourseData) {
      const team = toCourseTeam(data.team);
      const myRole = data.team.find((u) => u.userId === currentUserId)?.role || CourseRole.STUDENT;
      const enrollment: CourseEnrollment = { by: EnrollmentBy.INVITATION };

      const permissions = {
        canProxy: false,
        canStream: false,
        isPro: false,
        isDemo: false,
      };

      const comments = {
        isSubscribed: false,
        total: data.activities.numCommentsTotal ?? 0,
        seen: 0,
      };

      const course: Course = {
        id: data._id,
        code: data.details.code,
        title: data.details.title,
        schedule: toCourseSchedule(data.courseTimezone, data.dates),
        info: null,
        syllabus: null,
        timeline: null,
        myRole,
        team,
        type: CourseType.SYSTEM,
        enrollment,
        enrolledStudentsById: null,
        invitedStudentsById: null,
        removedStudentsById: null,
        permissions,
        payment: {
          cost: 0,
          hasPaid: false,
          payTill: -1,
        },
        comments,
        activities: toCourseActivities(data),
        averages: null,
        studentAveragesByStudentId: null,
        status: toCourseStatus({}, true),
      };

      state.byId[course.id] = course;
      state.archivedOrder.push(course.id);
      state.total++;
    }
  });

  builder.addCase(archiveCourse.success, (state) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    course.status.isArchived = true;
  });

  builder.addCase(fetchCourseDetails.success, (state, action) => {
    const courseId = state.currentCourse;

    if (!courseId) return;
    const course = state.byId[courseId];

    if (!course) return;

    const {
      courseData = [],
      courseDates,
      courseStatus,
      courseTeam,
      enrolmentType,
      joinCode,
      userData,
      syllabus,
      topics,
    } = action.payload;

    const team = toCourseTeam(courseTeam);

    course.team = team;
    course.comments.seen = userData?.numCommentsSeen ?? course.comments.seen;

    course.enrollment = toCourseEnrollment(enrolmentType, joinCode);
    course.schedule = toCourseSchedule(course.schedule.timezone, courseDates);
    course.status = toCourseStatus(courseStatus, course.status.isArchived);

    course.syllabus = {
      file: syllabus.name ? toCourseFile(syllabus) : undefined,
      topics: topics.map(({ _id }) => _id),
    };

    course.timeline = courseData
      .map<TimelineItem>((item) => ({
        id: item._id,
        type: item.nodeType,
        dueDateTime: item.details.dueDateTime,
      }))
      .sort(timelineComparer);
  });

  builder.addCase(joinCourseByJoinCode.success, (state, action) => {
    const { courseData, userData: userDataMap } = action.payload;
    for (const data of courseData) {
      const userData = userDataMap.courses?.[data._id];
      const team = toCourseTeam(data.team);

      const comments: Course['comments'] = {
        isSubscribed: Boolean(userData?.subscribed),
        total: data.activities.numCommentsTotal ?? 0,
        seen: userData?.numCommentsSeen ?? 0,
      };

      const course: Course = {
        id: data._id,
        code: data.details.code,
        title: data.details.title,
        schedule: toCourseSchedule(data.courseTimezone, data.dates),
        info: null,
        syllabus: null,
        timeline: null,
        myRole: userData?.role || CourseRole.STUDENT,
        team,
        type: data.courseType ?? CourseType.SYSTEM,
        enrollment: toCourseEnrollment(data.enrolmentType, data.joinCode),
        enrolledStudentsById: null,
        invitedStudentsById: null,
        removedStudentsById: null,
        permissions: toPermissions(data),
        payment: {
          cost: data.cost,
          hasPaid: Boolean(userData?.hasPaid),
          payTill: userData?.payTill ?? -1,
        },
        comments,
        activities: toCourseActivities(data, userData),
        averages: null,
        studentAveragesByStudentId: null,
        status: toCourseStatus(data.status),
      };

      state.byId[course.id] = course;
      state.order.push(course.id);
      state.total++;
    }
  });

  builder.addCase(fetchCourseInfo.success, (state, action) => {
    const { courseId, description, officeHours = [], readingList } = action.payload;

    const course = state.byId[courseId];
    if (!course) return;

    course.info = {
      officeHours,
      description,
      books: readingList.books.map(({ _id }) => _id),
      files: readingList.files.map(toCourseFile),
      links: readingList.links.map(({ _id }) => _id),
    };
  });

  builder.addCase(publishCourseInfo.success, (state) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    course.status.isInfoPublished = true;
  });

  builder.addCase(setCourseStartDate.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { startDate, startDateL } = action.payload;
    course.schedule.startDate = startDate;
    course.schedule.startDateStr = startDateL;
  });

  builder.addCase(setCourseEndDate.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { endDate, endDateL } = action.payload;
    course.schedule.endDate = endDate;
    course.schedule.endDateStr = endDateL;
  });

  builder.addCase(addCourseLink.success, (state, action) => {
    const { parent, link } = action.payload;
    if (parent !== 'course') return;

    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course?.info) return;

    course.info.links.push(link.id);
  });

  builder.addCase(removeCourseLink.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course?.info) return;

    const { linkId } = action.payload;
    course.info.links = course.info.links.filter((id) => id !== linkId);
  });

  builder.addCase(addCourseBook.success, (state, action) => {
    const { parent, book } = action.payload;
    if (parent !== 'course') return;

    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course?.info) return;

    course.info.books.push(book.id);
  });

  builder.addCase(removeCourseBook.success, (state, action) => {
    const { bookId } = action.payload;

    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course?.info) return;

    course.info.books = course.info.books.filter((id) => id !== bookId);
  });

  builder.addCase(editCourseDescription.success, (state, action) => {
    const { description } = action.payload;

    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course?.info) return;

    course.info.description = description;
  });

  builder.addCase(saveOfficeHours.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course?.info) return;

    const { officeHours } = action.payload;
    course.info.officeHours = officeHours;
  });

  builder.addCase(saveCourseInfoFile.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course?.info) return;

    const file = action.payload;
    course.info.files.push(file);
  });

  builder.addCase(removeCourseInfoFile.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course?.info) return;

    const { fileId } = action.payload;
    course.info.files = course.info.files.filter((file) => file.id !== fileId);
  });

  builder.addCase(saveCourseSyllabusFile.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course?.syllabus) return;

    const syllabusFile = action.payload;
    course.syllabus.file = syllabusFile;
  });

  builder.addCase(addCourseTeamMember.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const member = action.payload;

    if (member.role === CourseRole.INSTRUCTOR) {
      course.team.instructors.push(member);
    }

    if (member.role === CourseRole.TA) {
      course.team.assistants.push(member);
    }
  });

  builder.addCase(removeCourseMember.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { memberId, role } = action.payload;

    if (role === CourseRole.INSTRUCTOR) {
      course.team.instructors = course.team.instructors.filter(
        (instructor) => instructor.userId !== memberId
      );
    }

    if (role === CourseRole.TA) {
      course.team.assistants = course.team.assistants.filter((assistant) => assistant.userId !== memberId);
    }
  });

  builder.addCase(fetchCourseEnrollments.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { enrolledStudents, pendingStudents, removedStudents } = action.payload;

    course.enrolledStudentsById = {};
    course.invitedStudentsById = {};
    course.removedStudentsById = {};

    for (const student of enrolledStudents) {
      const { userId, name, avatar, emailId, role } = student.identifiers;
      course.enrolledStudentsById[userId] = {
        userId,
        emailId,
        name,
        avatar,
        role,
        status: student.courseStatus,
        joinedOn: student.joinedOn,
        lastActiveOn: student.lastActive ?? -1,
      };
    }

    for (const student of pendingStudents) {
      course.invitedStudentsById[student._id] = {
        userId: student._id,
        emailId: student.emailId,
      };
    }

    for (const student of removedStudents) {
      const { userId, name, avatar, emailId, role } = student.identifiers;
      course.removedStudentsById[userId] = {
        userId,
        emailId,
        name,
        avatar,
        role,
        status: student.courseStatus,
        joinedOn: student.joinedOn,
        lastActiveOn: student.lastActive ?? -1,
        removedOn: student.lastRemovedOn ?? -1,
      };
    }
  });

  builder.addCase(addCourseStudents.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { added, invited, removed } = action.payload;

    if (!course.enrolledStudentsById) {
      course.enrolledStudentsById = {};
    }

    if (!course.invitedStudentsById) {
      course.invitedStudentsById = {};
    }

    if (!course.removedStudentsById) {
      course.removedStudentsById = {};
    }

    for (const student of added) {
      const { userId, name, avatar, emailId, role, status } = student;

      // add to enrolled students map
      course.enrolledStudentsById[userId] = {
        userId,
        emailId,
        name,
        avatar,
        role,
        status,
        joinedOn: unix(),
        lastActiveOn: -1,
      };

      // as student is added, remove it from other student maps
      course.invitedStudentsById[userId] = undefined;
      course.removedStudentsById[userId] = undefined;
    }

    for (const student of invited) {
      const { userId, emailId } = student;
      course.invitedStudentsById[userId] = { userId, emailId };
    }

    for (const student of removed) {
      const { userId, name, avatar, emailId, role, status } = student;
      const enrolledStudent = course.enrolledStudentsById[userId];

      // add to removed students map
      course.removedStudentsById[userId] = {
        userId,
        emailId,
        name,
        avatar,
        role,
        status,
        joinedOn: enrolledStudent?.joinedOn ?? -1,
        lastActiveOn: enrolledStudent?.lastActiveOn ?? -1,
        removedOn: unix(),
      };

      // as student is removed, remove it from other student maps
      course.invitedStudentsById[userId] = undefined;
      course.enrolledStudentsById[userId] = undefined;
    }
  });

  builder.addCase(removeCourseStudent.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { studentId, removedOn } = action.payload;

    const enrolled = course.enrolledStudentsById?.[studentId];
    const invited = course.invitedStudentsById?.[studentId];

    if (invited) {
      course.invitedStudentsById![studentId] = undefined;
      return;
    }

    if (!enrolled) return;

    course.enrolledStudentsById![studentId] = undefined;

    if (!course.removedStudentsById) course.removedStudentsById = {};

    course.removedStudentsById[studentId] = { ...enrolled, removedOn };
  });

  builder.addCase(reinstateRemovedStudent.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { studentId } = action.payload;

    const student = course.removedStudentsById?.[studentId];
    if (!student) return;

    // remove from deleted student map
    course.removedStudentsById![studentId] = undefined;

    if (!course.enrolledStudentsById) course.enrolledStudentsById = {};

    const { removedOn, ...restStudentData } = student;

    // add to enrolled student map
    course.enrolledStudentsById[studentId] = restStudentData;
  });

  builder.addCase(fetchCourseAveragesForCourseTeam.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { averages } = action.payload;

    course.averages = averages;
  });

  builder.addCase(fetchStudentAveragesForCourseTeam.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { averages, studentId } = action.payload;

    if (!course.studentAveragesByStudentId) course.studentAveragesByStudentId = {};

    course.studentAveragesByStudentId[studentId] = averages;
  });

  builder.addCase(fetchMyCourseAnalytics.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { studentAverages, studentId } = action.payload;

    if (!course.studentAveragesByStudentId) course.studentAveragesByStudentId = {};

    course.studentAveragesByStudentId[studentId] = studentAverages;
  });

  builder.addCase(addCourseTopic.success, (state, action) => {
    if (!state.currentCourse) return;
    const course = state.byId[state.currentCourse];

    const { type, topicId } = action.payload;
    if (type !== TopicType.PARENT || !course?.syllabus) return;

    course.syllabus.topics.push(topicId);
  });

  builder.addCase(deleteCourseTopic.success, (state, action) => {
    if (!state.currentCourse) return;
    const course = state.byId[state.currentCourse];

    const { type, topicId } = action.payload;
    if (type !== TopicType.PARENT || !course?.syllabus) return;

    course.syllabus.topics = course.syllabus.topics.filter((id) => id !== topicId);
  });

  builder.addCase(createAnnouncement.success, (state, action) => {
    const announcement = action.payload;
    addAnnouncementCaseReducer({
      announcementDetails: { ...action.payload, type: AnnouncementType.MANUAL },
      announcementId: announcement._id,
      course: state.byId[state.currentCourse || ''],
    });
  });

  builder.addCase(announcementCreated, (state, action) => {
    const { announcementId, courseId, details } = action.payload;
    addAnnouncementCaseReducer({
      announcementDetails: details,
      announcementId,
      course: state.byId[courseId],
    });
  });

  builder.addCase(createAssignment.success, (state, action) => {
    const { assignment } = action.payload;

    if (!assignment || !state.currentCourse) return;
    const course = state.byId[state.currentCourse];

    if (!course) return;
    course.activities.assignments.total++;

    if (!course.timeline?.length) return;

    const timelineItem: TimelineItem = {
      id: assignment.id,
      type: TimelineItemType.ASSIGNMENT,
      dueDateTime: assignment.preferences.dueDateTime,
    };

    insertByBinarySearch(timelineItem, course.timeline, timelineComparer);
  });

  builder.addCase(deleteAssignment.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { assignment } = action.payload;

    course.activities.assignments.total--;

    const isPublished = assignment.publishedOn > 0;
    const isSeen = isPublished && assignment.firstAccessedOn > 0;

    if (isSeen) course.activities.seen--;

    if (isPublished) {
      course.activities.total--;
      course.activities.assignments.published--;
    }

    if (!course.timeline?.length) return;
    course.timeline = course.timeline.filter((item) => item.id !== assignment.id);
  });

  builder.addCase(deleteClass.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    course.activities.classes.published--;
    course.activities.classes.total--;

    if (!course.timeline?.length) return;

    const { classId } = action.payload;
    course.timeline = course.timeline.filter((item) => item.id !== classId);
  });

  builder.addCase(createQuiz.success, (state) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    course.activities.quizzes.total++;
  });

  builder.addCase(deleteQuiz.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { quiz } = action.payload;

    course.activities.quizzes.total--;

    const isPublished = quiz.publishedOn > 0;
    const isSeen = isPublished && quiz.firstAccessedOn > 0;

    if (isSeen) course.activities.seen--;

    if (isPublished) {
      course.activities.total--;
      course.activities.quizzes.published--;
    }
  });

  builder.addCase(publishQuiz.success, (state) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    course.activities.total++;
    course.activities.seen++;
    course.activities.quizzes.published++;
  });

  builder.addCase(quizPublished, (state, action) => {
    const { courseId, currentUser, doesExist, sender } = action.payload;
    activityPublished({
      course: state.byId[courseId],
      activityType: 'quizzes',
      currentUser,
      isActivityExists: doesExist,
      sender,
    });
  });

  builder.addCase(createPoll.success, (state) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    course.activities.polls.total++;
  });

  builder.addCase(deletePoll.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { poll } = action.payload;

    course.activities.polls.total--;

    const isPublished = poll.publishedOn > 0;
    const isSeen = isPublished && poll.firstAccessedOn > 0;

    if (isSeen) course.activities.seen--;

    if (isPublished) {
      course.activities.total--;
      course.activities.polls.published--;
    }
  });

  builder.addCase(publishPoll.success, (state) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    course.activities.total++;
    course.activities.seen++;
    course.activities.polls.published++;
  });

  builder.addCase(pollPublished, (state, action) => {
    const { courseId, currentUser, doesExist, sender } = action.payload;
    activityPublished({
      course: state.byId[courseId],
      activityType: 'polls',
      currentUser,
      isActivityExists: doesExist,
      sender,
    });
  });

  builder.addCase(createResource.success, (state) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    course.activities.resources.total++;
  });

  builder.addCase(deleteResource.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { resource } = action.payload;

    course.activities.resources.total--;

    const isPublished = resource.publishedOn > 0;
    const isSeen = isPublished && resource.firstAccessedOn > 0;

    if (isSeen) course.activities.seen--;

    if (isPublished) {
      course.activities.total--;
      course.activities.resources.published--;
    }
  });

  builder.addCase(publishResource.success, (state) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    course.activities.total++;
    course.activities.seen++;
    course.activities.resources.published++;
  });

  builder.addCase(resourcePublished, (state, action) => {
    const { courseId, currentUser, doesExist, sender } = action.payload;
    activityPublished({
      course: state.byId[courseId],
      activityType: 'resources',
      currentUser,
      isActivityExists: doesExist,
      sender,
    });
  });

  builder.addCase(incrementResourceViews.success, (state, action) => {
    const { courseId, isFirstAccess } = action.payload;

    const course = state.byId[courseId];
    if (!course || !isFirstAccess) return;

    course.activities.seen++;
  });

  builder.addCase(createDiscussion.success, (state) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    course.activities.discussions.total++;
  });

  builder.addCase(deleteDiscussion.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { discussion } = action.payload;

    course.activities.discussions.total--;

    const isPublished = discussion.publishedOn > 0;
    const isSeen = isPublished && discussion.firstAccessedOn > 0;

    if (isSeen) course.activities.seen--;

    if (isPublished) {
      course.activities.total--;
      course.activities.discussions.published--;
    }
  });

  builder.addCase(publishDiscussion.success, (state) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    course.activities.total++;
    course.activities.seen++;
    course.activities.discussions.published++;
  });

  builder.addCase(discussionPublished, (state, action) => {
    const { courseId, currentUser, doesExist, sender } = action.payload;
    activityPublished({
      course: state.byId[courseId],
      activityType: 'discussions',
      currentUser,
      isActivityExists: doesExist,
      sender,
    });
  });

  builder.addCase(markDiscussionAsViewed.success, (state, action) => {
    const { courseId, isFirstAccess } = action.payload;

    const course = state.byId[courseId];
    if (!course || !isFirstAccess) return;

    course.activities.seen++;
  });

  builder.addCase(createQuery.success, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { isAnonymous } = action.payload;
    if (isAnonymous) return;

    course.activities.queries.total++;
    course.activities.queries.seen++;
  });

  builder.addCase(queryCreated, (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { currentUser, details, sender } = action.payload;
    const isSameUser = currentUser.userId === sender.userId;

    if (details.isAnon && currentUser.role === CourseRole.STUDENT) return;

    if (details.isAnon) {
      course.activities.queries.pending++;
    } else {
      course.activities.queries.total++;
    }

    if (isSameUser) {
      course.activities.queries.seen++;
    }
  });

  builder.addCase(approveQuery.success, (state, action) => {
    const { courseId } = action.payload;

    const course = state.byId[courseId];
    if (!course) return;

    course.activities.queries.total++;
    course.activities.queries.pending--;
  });

  builder.addCase(queryApproved, (state, action) => {
    const { courseId, currentUser } = action.payload;

    const course = state.byId[courseId];
    if (!course) return;

    course.activities.queries.total++;

    if (currentUser.role !== CourseRole.STUDENT) {
      course.activities.queries.pending--;
    }
  });

  builder.addCase(deleteQuery.success, (state, action) => {
    const { courseId } = action.payload;

    const course = state.byId[courseId];
    if (!course) return;

    course.activities.queries.pending--;
  });

  builder.addCase(markQueryAsViewed.success, (state, action) => {
    const { courseId, isFirstAccess } = action.payload;

    const course = state.byId[courseId];
    if (!course || !isFirstAccess) return;

    course.activities.queries.seen++;
  });

  builder.addCase(useSuggestedActivity.success, (state, action) => {
    const { activityType } = action.payload;

    if (activityType === SuggestedActivityType.ASSIGNMENT) return;

    const course = state.byId[state.currentCourse || ''];
    if (!course) return;

    const activityTypePlural = SuggestedActivityToPlural[activityType];
    course.activities[activityTypePlural].total++;
  });

  builder.addMatcher(isAnyOf(publishAssignment.success, editPublishedAssignment.success), (state, action) => {
    if (!state.currentCourse) return;

    const course = state.byId[state.currentCourse];
    if (!course) return;

    const { assignmentId, dueDateTime } = action.payload;

    if (publishAssignment.success.match(action)) {
      course.activities.total++;
      course.activities.assignments.published++;
    }

    if (!course.timeline) return;
    const assignment = course.timeline.find((i) => i.id === assignmentId);

    if (!assignment) return;
    assignment.dueDateTime = dueDateTime;

    // re-order timeline to move assignment to correct position
    course.timeline.sort(timelineComparer);
  });

  builder.addMatcher(isAnyOf(fetchQuizDetails.success, fetchPollDetails.success), (state, action) => {
    const { courseId, isFirstAccess } = action.payload;

    const course = state.byId[courseId];
    if (!course || !isFirstAccess) return;

    course.activities.seen++;
  });
});

const coursesReducer = pipeReducers(
  createCommentReducer({ initialState, reducerContext: CommentContext.COURSE }),
  baseCoursesReducer
);

export default coursesReducer;
