import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { redeemCoupon } from '../../../../db/courses/actions';
import { useRequestDispatch } from '../../../../utils/request-actions';
import { usePaymentWallContext } from '../../Context';
import { PaymentStep } from '../../types';
import { useCoursePaymentContext } from '../Context';
import { FormValues } from './types';
import { validationSchema } from './validators';

const useCouponCode = () => {
  const requestDispatch = useRequestDispatch();

  const { currentPaymentStep, setCurrentPaymentStep } = usePaymentWallContext();
  const { closeDrawer } = useCoursePaymentContext();

  const isVisible = currentPaymentStep.name === PaymentStep.COUPON_CODE;

  const formMethods = useForm<FormValues>({
    defaultValues: {
      couponCode: '',
    },
    resolver: joiResolver(validationSchema),
  });

  const { reset, setError } = formMethods;

  const handleCloseDrawer = () => {
    reset();
    closeDrawer();
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      const response = await requestDispatch(redeemCoupon, { coupon: data.couponCode });
      if (response.message === 'success') {
        setCurrentPaymentStep({ name: PaymentStep.PAYMENT_SUCCESS });
      } else {
        setError('couponCode', { message: response.errorMessage });
      }
    } catch (error: any) {
      setError('couponCode', error);
    }
  };

  return {
    isVisible,
    handleCloseDrawer,
    formMethods,
    onSubmit,
  };
};

export default useCouponCode;
