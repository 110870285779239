import { forwardRef, ReactNode, Ref } from 'react';
import { Rnd } from 'react-rnd';

import { Portal } from '@mui/material';

import clsx from 'clsx';

import { pipContainerClasses } from '../constants';
import PipBody from '../PipBody';
import { PipContainerInstance, PipContainerMode } from '../types';
import { Root } from './styles';
import usePipContainerVM, { VMProps } from './vm';

interface Props extends VMProps {
  className?: string;
  classes?: Partial<typeof pipContainerClasses>;
  children: ReactNode;
}

const PipContainer = (
  { className, classes, children, ...vmProps }: Props,
  ref: Ref<PipContainerInstance>
) => {
  const {
    bodyRef,
    disableDragging,
    disableResize,
    handleDragStop,
    handleResize,
    handleToggleFullscreen,
    isFullscreen,
    lockAspectRatio,
    lockAspectRatioExtraHeight,
    lockAspectRatioExtraWidth,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    mode,
    parentRef,
    position,
    rndRef,
    size,
  } = usePipContainerVM(vmProps, ref);

  const enableSideResize = !lockAspectRatio;
  const enableCornerResize = Boolean(lockAspectRatio);

  return (
    <Portal>
      <Root
        ref={parentRef}
        className={clsx(className, classes?.root, pipContainerClasses.root, {
          [pipContainerClasses.modePip]: !isFullscreen && mode === PipContainerMode.PIP,
          [pipContainerClasses.modeEmbedded]: !isFullscreen && mode === PipContainerMode.EMBEDDED,
          [pipContainerClasses.modeFullscreen]: isFullscreen,
        })}
      >
        <Rnd
          bounds="parent"
          className={clsx(pipContainerClasses.container, classes?.container)}
          disableDragging={disableDragging}
          enableResizing={
            disableResize
              ? false
              : {
                  top: enableSideResize,
                  right: enableSideResize,
                  bottom: enableSideResize,
                  left: enableSideResize,
                  topLeft: enableCornerResize,
                  topRight: enableCornerResize,
                  bottomLeft: enableCornerResize,
                  bottomRight: enableCornerResize,
                }
          }
          lockAspectRatio={lockAspectRatio}
          lockAspectRatioExtraHeight={lockAspectRatioExtraHeight}
          lockAspectRatioExtraWidth={lockAspectRatioExtraWidth}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          minHeight={minHeight}
          minWidth={minWidth}
          onDragStop={handleDragStop}
          onResize={handleResize}
          position={position}
          ref={rndRef}
          size={size}
        >
          <PipBody
            ref={bodyRef}
            className={clsx(pipContainerClasses.body, classes?.body)}
            onToggleFullscreen={handleToggleFullscreen}
          >
            {children}
          </PipBody>
        </Rnd>
      </Root>
    </Portal>
  );
};

export default forwardRef(PipContainer);
