import { Position, Rnd } from 'react-rnd';

import { PipSize } from '../types';

export const DEFAULT_MIN_HEIGHT = 275;
export const DEFAULT_MIN_WIDTH = 360;

export function savePipDimensions(key: string, size: PipSize) {
  window.localStorage.setItem(`pip_${key}`, `${size.width},${size.height}`);
}

export function getSavedPipDimensions(key: string): PipSize | null {
  const value = window.localStorage.getItem(`pip_${key}`);
  if (!value) return null;

  const [width, height] = value.split(',').map((item) => parseFloat(item));
  if (Number.isNaN(width) || Number.isNaN(height)) return null;

  return { width, height };
}

export function getInitialPosition({ parent, size }: { parent: HTMLElement; size: PipSize }): Position {
  const { height } = parent.getBoundingClientRect();
  return { x: 0, y: height - size.height }; // bottom-left corner
}

export function getInitialSize({
  aspectRatio,
  id,
  lockAspectRatioExtraHeight = 0,
  lockAspectRatioExtraWidth = 0,
  minHeight = DEFAULT_MIN_HEIGHT,
  minWidth = DEFAULT_MIN_WIDTH,
  skipLocalStorage = false,
}: {
  aspectRatio?: number;
  id: string;
  lockAspectRatioExtraHeight?: number;
  lockAspectRatioExtraWidth?: number;
  minHeight?: number;
  minWidth?: number;
  skipLocalStorage?: boolean;
}): PipSize {
  const size = skipLocalStorage ? null : getSavedPipDimensions(id);

  if (size) return size;

  if (!aspectRatio) {
    return {
      width: minWidth + lockAspectRatioExtraWidth,
      height: minHeight + lockAspectRatioExtraHeight,
    };
  }

  const w1 = aspectRatio * (minHeight - lockAspectRatioExtraHeight) + lockAspectRatioExtraWidth;
  const h1 = minHeight;

  const w2 = minWidth;
  const h2 = (minWidth - lockAspectRatioExtraWidth) / aspectRatio + lockAspectRatioExtraHeight;

  if (w1 >= minWidth && h1 >= minHeight && w2 >= minWidth && h2 >= minHeight) {
    // if both satisfies the constraints then pick smaller area
    const area1 = w1 * h1;
    const area2 = w2 * h2;
    return {
      width: area1 > area2 ? w2 : w1,
      height: area1 > area2 ? h2 : h1,
    };
  }

  if (w1 >= minWidth && h1 >= minHeight) {
    return { width: w1, height: h1 };
  }

  return { width: w2, height: h2 };
}

export function setPipModeDimensions({
  parent,
  rnd,
  size,
}: {
  parent: HTMLDivElement;
  rnd: Rnd;
  size: PipSize;
}) {
  parent.style.top = '';
  parent.style.left = '';
  parent.style.height = '';
  parent.style.width = '';

  // wait for parent dimension re-calculation by browser
  return window.requestAnimationFrame(() => {
    const { height } = parent.getBoundingClientRect();
    const position = { x: 0, y: height - size.height };

    rnd.updateSize(size);
    rnd.updatePosition(position);

    const content = rnd.getSelfElement();
    if (!content) return;

    content.style.transform = `translate(0px, ${position.y}px)`;
    content.style.height = `${size.height}px`;
    content.style.width = `${size.width}px`;
  });
}

export function setEmbeddedModeDimensions({
  aspectRatio,
  lockAspectRatioExtraHeight = 0,
  lockAspectRatioExtraWidth = 0,
  parent,
  rnd,
  target,
}: {
  aspectRatio?: number;
  lockAspectRatioExtraHeight?: number;
  lockAspectRatioExtraWidth?: number;
  parent: HTMLDivElement;
  rnd: Rnd;
  target: HTMLElement;
}) {
  const { top, left, height: targetHeight, width: targetWidth } = target.getBoundingClientRect();

  const contentWidth = targetWidth - lockAspectRatioExtraWidth;
  const contentHeight = aspectRatio ? contentWidth / aspectRatio : targetHeight - lockAspectRatioExtraHeight;

  const width = contentWidth + lockAspectRatioExtraWidth;
  const height = contentHeight + lockAspectRatioExtraHeight;

  if (aspectRatio) {
    // modify target's height to accommodate pip-content's height with aspect-ratio
    target.style.height = `${height}px`;
  }

  parent.style.top = `${top}px`;
  parent.style.left = `${left}px`;
  parent.style.height = `${height}px`;
  parent.style.width = `${width}px`;

  rnd.updateOffsetFromParent();

  rnd.updateSize({ width, height });
  rnd.updatePosition({ x: 0, y: 0 });

  const content = rnd.getSelfElement();
  if (!content) return;

  content.style.transform = '';
  content.style.height = `${height}px`;
  content.style.width = `${width}px`;
}
