import {
  DependencyList,
  ForwardedRef,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import axios from 'axios';

import { GetPreSignedURLRequestConfig, PreSignedURLResponse } from '../db/shared/types';
import { getImageSizeFromUrl, splitFileNameAndExtension } from './file';
import { checkIfScrolledToBottom, debounce, toShortID } from './helpers';
import Logger from './logger';
import request from './request';

const log = Logger.create('UploadButton');

/**
 * Updates title of the html document
 * @param title page title
 */
export const useDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

export const useImage = (src?: string) => {
  const [fileSize, setFileSize] = useState(0);
  const [{ loaded, failed }, setState] = useState({ loaded: false, failed: false });

  useEffect(() => {
    if (!src) {
      return undefined;
    }

    setState({ loaded: false, failed: false });

    const abortController = new AbortController();

    // no operation callback
    const noOp = () => {};
    const onLoad = () => setState({ loaded: true, failed: false });
    const onError = () => setState({ loaded: false, failed: true });

    getImageSizeFromUrl(src, abortController.signal).then(setFileSize).catch(noOp);

    let image: HTMLImageElement | null = new Image();

    image.src = src;

    image.addEventListener('load', onLoad, { once: true });
    image.addEventListener('error', onError, { once: true });

    return () => {
      image?.removeEventListener('load', onLoad);
      image?.removeEventListener('error', onError);
      image = null;
      abortController.abort();
    };
  }, [src]);

  return { loaded, failed, fileSize };
};

/**
 * Shortens mongoId, returns last 8 characters of mongoId
 * @param id mongoId to be shortened
 */
export const useShortId = (id: MongoId): ShortId => {
  return useMemo(() => toShortID(id), [id]);
};

/**
 * Used for handling scroll stop event. It waits to call callback() until
 * scrolling on the target element is stopped for specified time delay
 * @param target target DOM element reference
 * @param callback function to be called on scroll end
 * @param delay delay in milli-seconds
 */
export const useScrollEnd = (target: HTMLElement | null, callback: () => unknown, delay = 300) => {
  const cb = useMemo(() => callback, [callback]);

  useEffect(() => {
    if (!target) return;

    const handleScroll = debounce(cb, delay);
    target.addEventListener('scroll', handleScroll);

    return () => {
      target.removeEventListener('scroll', handleScroll);
    };
  }, [cb, delay, target]);
};

/**
 * Used to check if an element is scrolled to bottom.
 * It waits for specified delay time to update its state.
 * @param target target DOM element reference
 * @param delay delay in mill-seconds
 * @returns a boolean, true when target element is scrolled to bottom
 */
export const useIsScrolledToBottom = (target: HTMLElement | null, delay = 300, reversed?: boolean) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(checkIfScrolledToBottom(target));

  const handleScroll = useMemo(
    () => debounce(() => setIsScrolledToBottom(checkIfScrolledToBottom(target, 10, reversed)), delay),
    [delay, target, reversed]
  );

  useEffect(() => {
    if (!target) return;

    target.addEventListener('scroll', handleScroll);

    return () => {
      target.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, target]);

  return isScrolledToBottom;
};

/**
 * Shows an browser's native alert to warn user for leaving the page
 * @param shouldWarn whether user should be warned
 */
export function useWarnBeforeWindowClose(shouldWarn?: boolean): void;

/**
 * Shows an browser's native alert to warn user for leaving the page
 * @param shouldWarn callback, will be called if user tries to leave the page, return true to show alert for warning the user
 * @param deps callback dependencies for useCallback()
 */
export function useWarnBeforeWindowClose(shouldWarn: () => boolean, deps: React.DependencyList): void;
export function useWarnBeforeWindowClose(
  _shouldWarn: boolean | (() => boolean) = true,
  deps: DependencyList = []
) {
  // eslint-disable-next-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
  _shouldWarn = typeof _shouldWarn === 'boolean' ? _shouldWarn : useCallback(_shouldWarn, deps);

  const handleCloseWindow = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();
    // new browsers does not respect these custom messages so pointless to make it customizable
    return (event.returnValue = 'Are you sure you want to exit?');
  }, []);

  useEffect(() => {
    const shouldWarn = typeof _shouldWarn === 'boolean' ? _shouldWarn : _shouldWarn();
    if (!shouldWarn) return;

    window.addEventListener('beforeunload', handleCloseWindow, { capture: true });
    return () => {
      window.removeEventListener('beforeunload', handleCloseWindow, { capture: true });
    };
  }, [_shouldWarn, handleCloseWindow]);
}

/**
 * This hook provides a function that returns whether the component is still mounted.
 * This is useful as a check before calling set state operations which will generates
 * a warning when it is called when the component is unmounted.
 * @returns a function
 *
 * @see https://stackoverflow.com/a/70716215/9709887
 */
export function useMounted(): () => boolean {
  const mountedRef = useRef(false);

  useEffect(function useMountedEffect() {
    mountedRef.current = true;
    return function useMountedEffectCleanup() {
      mountedRef.current = false;
    };
  }, []);

  return useCallback(
    function isMounted() {
      return mountedRef.current;
    },
    [mountedRef]
  );
}

interface UseFileUploadProps {
  /**
   * Request payload to fetch S3's pre-signed upload url and
   * file's meta-data
   */
  requestConfig: GetPreSignedURLRequestConfig;
}

export const useFileUpload = ({ requestConfig }: UseFileUploadProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState({ isIndeterminate: true, total: 0, uploaded: 0 });

  const getPreSignedURL = async (file: File) => {
    const { url, headers, data: payload } = requestConfig;
    const { name: originalFileName, extension: fileType } = splitFileNameAndExtension(file.name, file.name);

    const { data } = await request.post<PreSignedURLResponse>(
      url,
      { fileType, originalFileName, ...payload },
      { headers }
    );

    return { ...data, originalName: originalFileName };
  };

  const handleUpload = async (
    file: File
  ): Promise<{ name: string; type: string; url: string; originalName: string } | { error: unknown }> => {
    try {
      setIsUploading(true);

      // get pre-signed url for s3 upload
      const { url, contentType, name, originalName, type } = await getPreSignedURL(file);

      // upload to s3
      await axios.put(url, file, {
        headers: {
          'Content-Type': contentType,
        },
        onUploadProgress: (e: ProgressEvent) => {
          if (!e.lengthComputable) return;
          setProgress({ isIndeterminate: false, total: e.total, uploaded: e.loaded });
        },
      });

      setProgress({ isIndeterminate: true, total: 0, uploaded: 0 });
      return { name, type, url, originalName };
    } catch (error) {
      log.error(error);
      return { error };
    } finally {
      setIsUploading(false);
    }
  };

  return { isUploading, onUpload: handleUpload, progress };
};

export function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (event: WindowEventMap[K]) => void,
  element?: undefined,
  options?: boolean | AddEventListenerOptions
): void;

// Element Event based useEventListener interface
export function useEventListener<K extends keyof HTMLElementEventMap, T extends HTMLElement = HTMLDivElement>(
  eventName: K,
  handler: (event: HTMLElementEventMap[K]) => void,
  element: RefObject<T>,
  options?: boolean | AddEventListenerOptions
): void;

// Document Event based useEventListener interface
export function useEventListener<K extends keyof DocumentEventMap>(
  eventName: K,
  handler: (event: DocumentEventMap[K]) => void,
  element: RefObject<Document>,
  options?: boolean | AddEventListenerOptions
): void;

export function useEventListener<
  KW extends keyof WindowEventMap,
  KH extends keyof HTMLElementEventMap,
  T extends HTMLElement | void = void
>(
  eventName: KW | KH,
  handler: (event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event) => void,
  element?: RefObject<T>,
  options?: boolean | AddEventListenerOptions
) {
  // Create a ref that stores handler
  const savedHandler = useRef(handler);

  useLayoutEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    // Define the listening target
    const targetElement: T | Window = element?.current || window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener: typeof handler = (event) => savedHandler.current(event);

    targetElement.addEventListener(eventName, eventListener, options);

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, options]);
}

export function useForwardedRef<T>(ref: ForwardedRef<T>) {
  const innerRef = useRef<T>(null);

  useEffect(() => {
    if (!ref) return;
    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  });

  return innerRef;
}
