import { forwardRef, ReactNode, Ref } from 'react';

import { Root } from './styles';
import usePipBody, { VMProps } from './vm';

interface Props extends VMProps {
  className?: string;
  children: ReactNode;
}

const PipBody = ({ className, children, ...vmProps }: Props, ref: Ref<HTMLDivElement>) => {
  const { rootRef } = usePipBody(vmProps, ref);
  return (
    <Root ref={rootRef} className={className}>
      {children}
    </Root>
  );
};

export default forwardRef(PipBody);
