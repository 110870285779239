import { Children, isValidElement, ReactNode, useMemo } from 'react';

import { styled } from '@mui/material';

import AcadlyDialog, { dialogClasses, Props as DialogProps } from '../Dialog';
import Action from './Action';
import Body from './Body';
import { AlertProvider } from './Context';
import Footer from './Footer';
import Header from './Header';

const Dialog = styled(AcadlyDialog)<{ zIndex?: number }>(({ theme, zIndex }) => ({
  zIndex,
  [`& .${dialogClasses.body}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  [`& .${dialogClasses.paper}`]: {
    borderRadius: 8,
  },
  [`& .${dialogClasses.footer}`]: {
    borderTop: 'none',
  },
}));

export interface Props {
  open?: boolean;
  onClose?: () => void;
  container?: DialogProps['container'];
  className?: string;
  classes?: DialogProps['classes'];
  /** @default true */
  fullWidth?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  children: ReactNode;
  zIndex?: number;
}

/**
 * Usage:
 * ```tsx
 * <Alert>
 *   <Alert.Header>Some title</Alert.Header>
 *   <Alert.Body>Are you sure</Alert.Body>
 *   <Alert.Footer>
 *     <Alert.Action>Cancel</Alert.Action>
 *     <Alert.Action>Okay</Alert.Action>
 *   </Alert.Footer>
 * </Alert>
 * ```
 */
const Alert = ({
  open = false,
  onClose,
  container,
  className,
  classes,
  fullWidth,
  maxWidth,
  children: _children,
  zIndex,
}: Props) => {
  const { header, footer, children } = useMemo(() => {
    const childrenArray = Children.toArray(_children);
    const header = childrenArray.find((child) => isValidElement(child) && child.type === Header);
    const footer = childrenArray.find((child) => isValidElement(child) && child.type === Footer);
    const children = childrenArray.filter((child) => child !== header && child !== footer);
    return { header, footer, children };
  }, [_children]);

  const handleClose = (
    event: React.MouseEvent | React.KeyboardEvent,
    reason: 'backdropClick' | 'escapeKeyDown' | 'custom'
  ) => {
    if (reason === 'backdropClick') {
      event.stopPropagation();
      return;
    }
    onClose?.();
  };

  return (
    <AlertProvider onClose={onClose}>
      <Dialog
        open={open}
        onClose={handleClose}
        container={container}
        header={() => header}
        footer={() => footer}
        className={className}
        classes={classes}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        disableFullscreen
        zIndex={zIndex}
      >
        {children}
      </Dialog>
    </AlertProvider>
  );
};

Alert.Header = Header;
Alert.Body = Body;
Alert.Footer = Footer;
Alert.Action = Action;

export default Alert;
