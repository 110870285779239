import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material';

import clsx from 'clsx';

import { CLASS_RECORDING_PLAY_AREA } from '../constants';
import routes, { ClassParams } from '../pages/routes';
import PipContainer, { PipContainerMode, PipContainerType, usePipContainer } from '../pip-container';
import PipHeader from '../pip-container/PipHeader';
import { generateClasses } from '../utils/helpers';
import Button, { Props as ButtonProps } from './Button';
import VideoJSPlayer, { PlayerType } from './VideoJSPlayer';

const mediaPlayerPipClasses = generateClasses('MediaPlayerPip', ['closeButton']);

const CloseButton = styled((props: ButtonProps) => (
  <Button {...props} className={clsx(mediaPlayerPipClasses.closeButton, props.className)} />
))(({ theme }) => ({
  [`&.${mediaPlayerPipClasses.closeButton}`]: {
    zIndex: 2,
    marginLeft: 'auto',
    color: theme.palette.common.white,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface MediaPlayerData {
  playerType: PlayerType;
  courseId: MongoId;
  classId: MongoId;
  title: string;
  fileName: string;
  url: string;
  recordingId: MongoId;
}

export const useMediaPlayer = () => {
  return usePipContainer<MediaPlayerData>({
    id: 'video_js_player_pip',
    type: PipContainerType.MEDIA_PLAYER,
  });
};

const useMediaPlayerPipVM = () => {
  const { ref, id, isOpen, close: handleClose, getContextData } = useMediaPlayer();
  const context = getContextData();

  const match = useRouteMatch<ClassParams>({
    path: routes.activities.path,
    exact: true,
  });

  const { classShortId } = match?.params || {};

  useEffect(
    function autoChangePipMode() {
      if (!ref.current) return;
      const pip = ref.current;
      if (classShortId && context?.classId.endsWith(classShortId)) {
        pip.requestModeChange(PipContainerMode.EMBEDDED);
      } else {
        pip.requestModeChange(PipContainerMode.PIP);
      }
    },
    [context?.classId, classShortId, ref]
  );

  return {
    id,
    ref,
    isOpen,
    handleClose,
    context,
  };
};

const MediaPlayerPip = () => {
  const { id, ref, isOpen, handleClose, context } = useMediaPlayerPipVM();

  if (!isOpen || !context) return null;

  const { playerType, url } = context;

  return (
    <PipContainer
      id={id}
      ref={ref}
      aspectRatio={16 / 9}
      embedTargetSelector={`#${CLASS_RECORDING_PLAY_AREA}`}
      minWidth={300}
      minHeight={162.75}
    >
      <PipHeader>
        <CloseButton size="small" onClick={handleClose} startIcon={<CloseIcon />} />
      </PipHeader>
      <VideoJSPlayer autoplay playerType={playerType} sources={[{ src: url }]} />;
    </PipContainer>
  );
};

export default MediaPlayerPip;
