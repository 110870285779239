import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectClass } from '../../db/classes/selectors';
import { markRemoteAttendance } from '../../db/online-meeting/actions';
import {
  remoteAttendanceStartedEvent,
  remoteAttendanceStopppedEvent,
} from '../../db/online-meeting/pusher-events';
import { selectOnlineMeeting } from '../../db/online-meeting/selectors';
import { ClassRole } from '../../db/shared/types';
import { usePusherEvent } from '../../pusher/bus';
import { getRandomInt } from '../../utils/helpers';
import { useRequestDispatch } from '../../utils/request-actions';
import { useZoomMeetingContext } from '../ZoomMeetingContext/hooks';

interface AttendanceData {
  attendanceId: MongoId;
  message: string;
  buttonText: string;
}

export default function useRemoteAttendanceInProgressDialogVM() {
  const requestDispatch = useRequestDispatch();
  const [attendanceData, setAttendanceData] = useState<AttendanceData | null>(null);

  const {
    state: { classId },
  } = useZoomMeetingContext();

  const cls = useSelector(selectClass(classId));

  const onlineDetails = useSelector(selectOnlineMeeting(classId));

  const isOpen = Boolean(attendanceData);
  const buttonText = attendanceData?.buttonText || '';
  const message = attendanceData?.message || '';

  usePusherEvent(
    remoteAttendanceStartedEvent,
    useCallback(
      function handleAttendanceStarted(event) {
        if (event.payload.classId !== classId) return;
        if (cls?.myRole !== ClassRole.STUDENT) return;

        const { displayMessages } = event.payload;
        const randomIndex = getRandomInt(0, displayMessages.length - 1);
        const { subtitle: message, buttonText } = displayMessages[randomIndex];

        setAttendanceData({
          attendanceId: event.payload.attendanceId,
          message,
          buttonText,
        });
      },
      [classId, cls?.myRole]
    )
  );

  const handleClose = useCallback(function handleClose() {
    setAttendanceData(null);
  }, []);

  usePusherEvent(
    remoteAttendanceStopppedEvent,
    useCallback(
      function handleAttendanceStopped(event) {
        if (event.payload.attendanceId !== attendanceData?.attendanceId) return;
        handleClose();
      },
      [attendanceData?.attendanceId, handleClose]
    )
  );

  const markAttendance = async () => {
    const meetingId = onlineDetails?.meetingId;
    const attendanceId = attendanceData?.attendanceId;

    if (!classId || !meetingId || !attendanceId) return;

    await requestDispatch(markRemoteAttendance, {
      attendanceId,
      classId,
      meetingId,
    });

    handleClose();
  };

  return { buttonText, isOpen, markAttendance, message };
}
