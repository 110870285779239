import { generateClasses } from '../utils/helpers';

export const pipContainerClasses = generateClasses('PipContainer', [
  'root',
  'container',
  'body',
  'modePip',
  'modeEmbedded',
  'modeFullscreen',
]);
